import {
  Directive,
  AfterContentInit,
  AfterViewInit,
  ElementRef,
  Inject
} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {
  constructor(private element: ElementRef) {
    console.log('AutoFocusDirective constructor');
  }

  ngAfterContentInit(): void {
    console.log('AutoFocusDirective ngAfterViewInit');
    this.element.nativeElement.focus();
  }
}
