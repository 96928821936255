import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, ControlContainer, FormControl } from '@angular/forms';

@Component({
  selector: 'kj-input',
  templateUrl: './kj-input.component.html',
  styleUrls: ['./kj-input.component.scss']
})
export class KJInputComponent implements OnInit {
  public form: FormGroup;
  public control: FormControl;
  @Input() controlName: string;
  @Input() name: string;
  @Input() iconName: string;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() passwordMatchDirective = false;
  @Input() autocomplete: string;

  public loading: boolean;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.form = <FormGroup>this.controlContainer.control;
    this.control = <FormControl>this.form.get(this.controlName);
  }
}
