import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SkillAreaQuestionModel } from '@models/skill-area-question-model';
import { Subscriber, Observable } from 'rxjs';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class SkillAreaService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private localizationService: LocalizationService
  ) {
    this.apiUrl = `${this.baseUrl}api/skillareas`;
  }

  getQuestions() {
    return new Observable<SkillAreaQuestionModel[]>(subscriber => {
      this.getQuestionsCall(subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs =>
        this.getQuestionsCall(obs)
      );
    });
  }

  getUserSkillsArea(userCertificationId) {
    return new Observable<SkillAreaQuestionModel[]>(subscriber => {
      this.getUserSkillsAreaCall(userCertificationId, subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs =>
        this.getUserSkillsAreaCall(userCertificationId, obs)
      );
    });
  }

  private getQuestionsCall(subscriber: Subscriber<SkillAreaQuestionModel[]>) {
    this.http
      .get<SkillAreaQuestionModel[]>(`${this.apiUrl}/questions`)
      .subscribe(
        questions => subscriber.next(questions),
        error => subscriber.error(error)
      );
  }

  private getUserSkillsAreaCall(
    userCertificationId: string,
    subscriber: Subscriber<SkillAreaQuestionModel[]>
  ) {
    this.http
      .get<SkillAreaQuestionModel[]>(
        `${this.apiUrl}/certifications/${userCertificationId}/questions`
      )
      .subscribe(
        questions => subscriber.next(questions),
        error => subscriber.error(error)
      );
  }
}
