import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: '[app-box-body]',
  templateUrl: './box-body.component.html',
  styleUrls: ['./box-body.component.scss']
})
export class BoxBodyComponent {
  @Input() class = '';

  constructor() {}

  @HostBinding('class')
  get hostClasses(): string {
    return ['box-body', this.class].join(' ');
  }
}
