import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { LocalizationService } from './localization.service';
import { SiteConfigModel } from '@models/site-config-model';

@Injectable({
  providedIn: 'root'
})
export class SiteConfigService {
  apiUrl: string;

  static cache: any = {};

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private localizationService: LocalizationService
  ) {
    this.apiUrl = `${this.baseUrl}api/siteConfig`;
  }

  getSiteConfig() {
    return new Observable<SiteConfigModel>(subscriber => {
      this.getSiteConfigCall(subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs => this.getSiteConfigCall(obs));
    });
  }

  private getSiteConfigCall(subscriber: Subscriber<SiteConfigModel>) {
    const code = this.localizationService.getLocale();

    if (SiteConfigService.cache[code]) {
      subscriber.next(SiteConfigService.cache[code]);
      return;
    }

    this.http.get<SiteConfigModel>(`${this.apiUrl}`).subscribe(
      siteConfig => {
        SiteConfigService.cache[code] = siteConfig;
        subscriber.next(siteConfig);
      },
      error => subscriber.error(error)
    );
  }
}
