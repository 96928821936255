import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutes } from './app.routing';
import { AuthModule } from './auth/auth.module';
import { LayoutModule } from './layout/layout.module';
import { AppComponent } from './app.component';
import { NotificationService } from '@services/notification.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LanguageService } from '@services/language.service';
import { CdkColumnDef } from '@angular/cdk/table';
import { AppComponentsModule } from '@shared/app-components.module';
import { SharedModule } from '@shared/shared.module';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
  MatDatepickerModule,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MatPaginatorIntl
} from '@angular/material';
import { GestureConfig } from '@angular/material';
import { NocacheInterceptor } from './nocache.interceptor';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { PaginatorIntlService } from '@services/Paginator-intl.service';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from './cookie-config';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppComponentsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    BrowserAnimationsModule,
    AuthModule,
    LayoutModule,
    SharedModule,
    MatDatepickerModule,
    MatMomentDateModule,
    // MatNativeDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatPasswordStrengthModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    // App Routing absolutely needs to be last in the list.
    AppRoutes
    // No more modules should be below this.
  ],
  providers: [
    MatDatepickerModule,
    MatMomentDateModule,
    // MatNativeDateModule,
    CdkColumnDef,
    NotificationService,
    CookieService,
    LanguageService,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL']
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NocacheInterceptor, multi: true },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { ...new MatDialogConfig(), width: 500 }
    },
    {
      // Localize the paginators on the search pages.
      provide: MatPaginatorIntl,
      useFactory: translate => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule {
  constructor(private readonly router: Router) {
    // for debugging routing issues
    // router.events.subscribe(console.log);
  }
}
