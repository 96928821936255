import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Roles } from '@models/roles';
import { AuthService } from '@services/auth.service';
import { LocalizationService } from '@services/localization.service';
import { NotificationLogService } from '@services/notification-log.service';
import { NotificationService } from '@services/notification.service';
import { CommonDialogService } from '@services/common-dialog.service';

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss']
})
export class SidebarLayoutComponent implements OnInit {
  unreadEmailCount: number;
  isDoneRegister = true;

  Roles = Roles;

  @Output() openSidebar: EventEmitter<void> = new EventEmitter();
  @Output() closeSidebar: EventEmitter<void> = new EventEmitter();
  constructor(
    private notificationLogService: NotificationLogService,
    private notificationService: NotificationService,
    private localizationService: LocalizationService,
    private authService: AuthService,
    public commonDialogService: CommonDialogService
  ) {}

  ngOnInit() {
    // If the user has not finished entering their details, them must do that first.
    const user = this.authService.getUser();
    if (
      !user.hasRegisteredForACertification &&
      this.authService.roleMatch([Roles.candidate])
    ) {
      this.isDoneRegister = false;
      return;
    }

    // Get unread email count and display message if there more than last time.
    if (
      this.authService.isCurrentUserCandidate() ||
      this.authService.isCurrentUserThirdParty()
    ) {
      this.notificationLogService.getUnreadCount().subscribe(r => {
        this.unreadEmailCount = r;
        const lastUnreadCount: number = +sessionStorage.getItem(
          'lastUnreadCount'
        );
        // if the unread count has increased since we have been using the app, toast the user again.
        const retoast = r > lastUnreadCount;
        sessionStorage.setItem('lastUnreadCount', r.toString());
        // use notification service for one-time per session popup to announce new emails
        const emailsNotified =
          sessionStorage.getItem('emailsNotified') === 'true';
        if (!emailsNotified || retoast) {
          let emailMessage = '';
          if (r > 1) {
            emailMessage = 'NotificationLogs.NewEmails';
          } else if (r === 1) {
            emailMessage = 'NotificationLogs.NewEmail';
          }

          if (emailMessage !== '') {
            this.localizationService
              .translate(emailMessage, { count: r })
              .subscribe({
                next: message => {
                  sessionStorage.setItem('emailsNotified', 'true');
                  this.notificationService.confirmSnackBar(message);
                }
              });
          }
        }
      });
    }
  }


  openSidebarMenu() {
    this.openSidebar.emit();
  }
  closeSidebarMenu() {
    this.closeSidebar.emit();
  }
}
