import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SuccessComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}
}
