import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserNotificationModel } from '@models/user-notification-model';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('BASE_URL') private baseUrl: string,
    private localizationService: LocalizationService
  ) {
    this.apiUrl = `${this.baseUrl}api/user/notifications`;
  }

  getUserNotification(triggerId: string) {
    return new Observable<UserNotificationModel>(subscriber => {
      this.getUserNotificationCall(triggerId, subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs =>
        this.getUserNotificationCall(triggerId, obs)
      );
    });
  }
  private getUserNotificationCall(
    triggerId: string,
    subscriber: Subscriber<UserNotificationModel>
  ) {
    return this.http
      .get<UserNotificationModel>(`${this.apiUrl}/${triggerId}`)
      .subscribe(
        nots => subscriber.next(nots),
        error => subscriber.error(error)
      );
  }

  getUserNotifications() {
    return new Observable<UserNotificationModel[]>(subscriber => {
      this.getUserNotificationsCall(subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs =>
        this.getUserNotificationsCall(obs)
      );
    });
  }
  private getUserNotificationsCall(
    subscriber: Subscriber<UserNotificationModel[]>
  ) {
    this.http.get<UserNotificationModel[]>(`${this.apiUrl}`).subscribe(
      mods => subscriber.next(mods),
      error => subscriber.error(error)
    );
  }

  putUserNotification(userNotificationModel: UserNotificationModel) {
    // always an update, user can't create new
    return this.http
      .put(`${this.apiUrl}/${userNotificationModel.id}`, userNotificationModel)
      .pipe(
        map(_ => {
          return { ...userNotificationModel };
        })
      );
  }

  markRead(logId: string) {
    return this.http.post(`${this.apiUrl}/markRead/${logId}`, {});
  }

  sendPreview(triggerTypeId: string) {
    return this.http.post(
      `${this.apiUrl}/sendPreview/${triggerTypeId}`,
      {},
      {
        observe: 'response'
      }
    );
  }
}

// markUserNotificationRead(comm: UserNotificationModel) {
//   return this.http.put(`${this.apiUrl}/markRead`, comm);
// }

// searchUserNotifications(
//   options: SearchOptions
// ): Observable<UserNotificationSearchResultModel> {
//   const params = {};
//   Object.keys(options)
//     .filter(x => options[x] !== null)
//     .forEach(x => {
//       params[x] = options[x].toString();
//     });
//   return this.http.get<UserNotificationSearchResultModel>(
//     `${this.apiUrl}/search`,
//     { params }
//   );
// }

// setUserNotification(usernotificationModel: UserNotificationModel) {
//   if (usernotificationModel.id) {
//     // Update existing
//     return this.http
//       .put(
//         `${this.apiUrl}/${usernotificationModel.id}`,
//         usernotificationModel
//       )
//       .pipe(
//         map((_) => {
//           return { ...usernotificationModel };
//         })
//       );
//   } else {
//     // Create new
//     return this.http
//       .post<UserNotificationModel>(`${this.apiUrl}`, usernotificationModel)
//       .pipe(
//         tap((usernotification) =>
//           date(usernotification, 'publishDate', 'expiryDate')
//         )
//       );
//   }
// }
