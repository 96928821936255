import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { getTimezoneAbbreviation } from '@services/date.service';

@Pipe({
  name: 'kortoDateTime'
})
export class KortoDateTimePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const zoneAbbr =
      args === 'noTz' ? ' ' : getTimezoneAbbreviation();
    return super.transform(value, `yyyy/MM/dd, h:mm a '${zoneAbbr}'`);
  }
}
