import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: '[app-box]',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent {
  @Input() class = '';

  constructor() {}

  @HostBinding('class')
  get hostClasses(): string {
    return ['box box-default', this.class].join(' ');
  }
}
