import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private preloaderStateSource = new Subject<string>();

  preloaderState$ = this.preloaderStateSource.asObservable();

  updatePreloaderState(state: string) {
    console.log('preloader state: ' + state);
    this.preloaderStateSource.next(state);
  }
}
