import {
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
  TemplateRef
} from '@angular/core';
import { AuthService } from '@services/auth.service';

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('hasRole') roles: string | Array<string>;
  // tslint:disable-next-line: no-input-rename
  @Input('onHasRole') authorizedStyle: string;
  // tslint:disable-next-line: no-input-rename
  @Input('onNotHasRole') unauthorizedStyle: string;

  isVisible = false;

  constructor(
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<any>,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.roles = Array.isArray(this.roles) ? this.roles : [this.roles];
    if (!this.roles) {
      this._viewContainer.clear();
    }

    if (this.authService.roleMatch(this.roles) && !this.isVisible) {
      this.isVisible = true;
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this.isVisible = false;
      this._viewContainer.clear();
    }
  }
}
