/**
 * Convert the specified properties of the object (or array of objects) to Dates.
 * @param object An object, or an array of objects that have date properties.
 * @param properties The properties that should be made into dates.
 */
export function date(object: object, ...properties: string[]): void {
  if (!object || object instanceof Date) {
    return;
  } else if (object instanceof Array) {
    object.forEach(item => date(item, ...properties));
  } else {
    properties.forEach(property => {
      const value = object[property];
      if (typeof value === 'string') {
        object[property] = new Date(value);
      } else if (typeof value === 'object') {
        date(value, ...properties);
      }
    });
  }
}

/**
 * Check a date to see if it is in the future or not.  Returns true if it is greater than now.
 * False if it is NULL or less than or equal to now.
 * @param date The date to check if it's after now
 */
export function isFutureDate(date: Date): boolean {
  return date && date.getTime && date.getTime() > Date.now();
}

export function getTimezoneAbbreviation(date: Date = new Date()): string {
  return date.toLocaleTimeString('en-ca', { timeZoneName: 'short' }).split(' ')[2];
}
