import { UserProfileModel } from '@models/user-profile-model';
import { Store } from 'rxjs-observable-store';
import { Injectable } from '@angular/core';
import { UserProfileService } from '@services/user-profile.service';
import { AuthService } from '@services/auth.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserRegistrationStore extends Store<UserProfileModel> {
  constructor(
    private userProfileService: UserProfileService,
    private authService: AuthService
  ) {
    super({
      address: {}
    } as UserProfileModel);
  }

  init() {
    const id = this.authService.getUserId();
    this.userProfileService.getUser(id).subscribe(res => this.setState(res));
  }

  submitUserProfile(userProfile: UserProfileModel) {
    return this.userProfileService
      .putUser(userProfile.id, { ...userProfile })
      .pipe(
        tap(() => {
          this.setState({
            ...userProfile
          });
          this.authService.setUserHasCompletedDetails();
        })
      );
  }
}
