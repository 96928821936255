import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, CanLoad, Route, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { NotificationService } from '@services/notification.service';
import { LocalizationService } from '@services/localization.service';
import { Roles } from '@models/roles';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private localizationService: LocalizationService,
    public notificationService: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // If user is not logged in reroute them to the login screen unless they're trying to reset their password.
    if (!this.authService.loggedIn()) {
      if (route.routeConfig.path !== '/reset-password') {
        this.router.navigate(['/login']);
        return false;
      }
    }

    //
    if (route.firstChild && route.firstChild.data) {
      const roles = route.firstChild.data['roles'] as Array<string>;
      if (roles && !this.authService.roleMatch(roles)) {
        this.showAccessDenied();
        return false;
      }
    }

    // If the user has not finished entering their personal details, then they must do that first.
    const user = this.authService.getUser();
    if (user.hasMissingDetails) {
      console.warn('User Registration is incomplete.')
      this.router.navigate(['/register/details']);
      return false;
    }

    // If the user is a candidate, but has not yet registered for certifications, go to the register pages.
    if (!user.hasRegisteredForACertification && this.authService.roleMatch([Roles.candidate])) {
      console.warn('Candidate has not registered for a certification.  Re-routing to Registration')
      this.router.navigate(['/register/certification']);
      return false;
    }

    // If a non Candidate user is trying to goto the raw dashboard, then reroute them to the proper page.
    if (state.url === '/dashboard') {
      if (this.authService.roleMatch([Roles.admin])) {
        this.router.navigate(['/admin']);
      } else if (this.authService.roleMatch([Roles.thirdParty])) {
        this.router.navigate(['/third-party']);
      } else if (this.authService.roleMatch([Roles.evaluator])) {
        this.router.navigate(['/evaluator']);
      }
    }

    return true;
  }

  canLoad(route: Route): boolean {
    if (!this.authService.loggedIn()) {
      return false;
    }

    if (route && route.data) {
      const roles = route.data['roles'] as Array<string>;
      if (roles && !this.authService.roleMatch(roles)) {
        this.router.navigate(['/']);
        this.showAccessDenied();
        return false;
      }
    }

    return true;
  }

  private showAccessDenied() {
    this.localizationService
      .translate('Notifications.AccessDenied')
      .subscribe(errorMessage => this.notificationService.errorDialog([errorMessage]));
  }
}
