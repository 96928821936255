import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl
} from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { UserRegisterModel } from '@models/user-register-model';
import { LocalizationService } from '@services/localization.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { CommonDialogService } from '@services/common-dialog.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;

  private passwordSubscription: Subscription;

  // Controls
  firstname = new FormControl('', Validators.required);
  lastname = new FormControl('', Validators.required);
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(8), this.validatePasswordStrength]);
  confirmPassword = new FormControl('', Validators.required);
  isAgreedToTOS = new FormControl(false, this.validateAgreementToTOS);

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private localizationService: LocalizationService,
    private dialog: MatDialog,
    public notificationService: NotificationService,
    public commonDialogService: CommonDialogService
  ) {
    this.registerForm = this.formBuilder.group({
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      password: this.password,
      confirmPassword: this.confirmPassword,
      isAgreedToTOS: this.isAgreedToTOS
    });
  }

  ngOnInit() {
    this.passwordSubscription = this.registerForm.get('password').valueChanges.subscribe(val => {
      this.revalidateConfirm();
    });
  }

  ngOnDestroy() {
    this.passwordSubscription.unsubscribe();
  }
  // Listening for Password change to revalidate the confirmPassword
  onChanges(): void {}

  validateAgreementToTOS(c: FormControl) {
    if (c.value) return null;
    return {
      mustAgreeToTOS: {
        valid: false
      }
    };
  }


  revalidate(name: 'password' | 'confirmPassword') {
    const password: AbstractControl = this.registerForm.controls[name];
    if (!password.invalid && password.touched) {
      password.updateValueAndValidity();
    }
  }
  revalidateConfirm() {
    this.revalidate('confirmPassword');
  }

  register() {
    if (this.registerForm.valid) {
      const userRegisterModel: UserRegisterModel = Object.assign({}, this.registerForm.value);
      this.authService.register(userRegisterModel).subscribe(
        () => {
          this.localizationService
            .translate('Notifications.RegistrationSuccessful')
            .subscribe(successMessage => this.notificationService.successSnackBar(successMessage));
        },
        error => {
          this.notificationService.showErrors(error);
        },
        () => {
          this.authService.login(userRegisterModel).subscribe(() => {
            this.router.navigate(['/register/details']);
          });
        }
      );
    }
  }

  validatePasswordStrength(c: FormControl) {
    // const PASSWORD_EXP = RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&]).{8,}');

    const isLower = /[a-z]/.test(c.value);
    const isUpper = /[A-Z]/.test(c.value);
    const isNumber = /[0-9]/.test(c.value);
    const isSpecial = /[^a-zA-Z0-9]/.test(c.value);
    const is8 = c.value.length >= 8;

    if (isLower && isUpper && isNumber && isSpecial && is8) return null;
    else
      return {
        validatePasswordStrength: {
          valid: false
        }
      };
  }
}
