import { Component } from '@angular/core';

@Component({
  selector: 'app-unsupported-browser-warning',
  templateUrl: './unsupported-browser-warning.component.html',
  styleUrls: ['./unsupported-browser-warning.component.scss']
})
export class UnsupportedBrowserWarningComponent {
  get isBrowserSupported() {
    return !/MSIE|Trident|Edge/.test(window.navigator.userAgent);
  }
}
