import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfessionModel } from '@models/profession-model';
import { Observable, Subscriber } from 'rxjs';
import { LocalizationService } from './localization.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfessionService {
  apiUrl: string;

  static cache: any = {};

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private localizationService: LocalizationService
  ) {
    this.apiUrl = `${this.baseUrl}api/professions`;
  }

  getProfessions() {
    return new Observable<ProfessionModel[]>(subscriber => {
      this.getProfessionsCall(subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs => this.getProfessionsCall(obs));
    });
  }

  private getProfessionsCall(subscriber: Subscriber<ProfessionModel[]>) {
    const code = this.localizationService.getLocale();

    if (ProfessionService.cache[code]) {
      subscriber.next(ProfessionService.cache[code]);
      return;
    }

    this.http
      .get<ProfessionModel[]>(`${this.apiUrl}`)
      .pipe(
        map(professions => {
          // Sort by a - z, but make sure 'notCandidate' is always the last item in the list.
          const notCandidate = professions.find(p => p.type === 'NotCandidate');
          professions = professions.filter(p => p != notCandidate);
          professions = professions.sort((a, b) => a.label.localeCompare(b.label, 'fr')); // localCompare() is used so that 'É' is sorted properly. English doesn't have non-ASCII chars, so it can safely be sorted with the FR locale.
          return [...professions, notCandidate];
        })
      )
      .subscribe(
        professions => {
          ProfessionService.cache[code] = professions;
          subscriber.next(professions);
        },
        error => subscriber.error(error)
      );
  }
}
