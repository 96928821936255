import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSpan'
})
export class TimeSpanPipe implements PipeTransform {
  transform(seconds: number, args?: any): any {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  }
}
