import { NgModule } from '@angular/core';
import { LanguageToggleComponent } from '@components/language-toggle/language-toggle.component';
import { SharedModule } from './shared.module';
import { CommonModule } from '@angular/common';
import { KJInputComponent } from '@kj-controls/kj-input/kj-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PopupSupportComponent } from '@components/popup-support/popup-support.component';
import { BoxComponent } from '@components/box/box.component';
import { BoxBodyComponent } from '@components/box/box-body/box-body.component';
import { BoxDividerComponent } from '@components/box/box-divider/box-divider.component';
import { BoxHeaderComponent } from '@components/box/box-header/box-header.component';
import { KjErrorComponent } from '@kj-controls/kj-error/kj-error.component';
import { RouterModule } from '@angular/router';
import { ThirdPartyEmailsComponent } from '../user/user-certifications/user-certification/third-party-emails/third-party-emails.component';
import { EditRowComponent } from '../user/user-certifications/user-certification/third-party-emails/edit-row/edit-row.component';
import { CompletedCertificationDetailsComponent } from '../user/user-certifications/user-certification/completed-certification-details/completed-certification-details.component';
import { UserCertificationTestItemComponent } from '../user/user-certifications/user-certification/user-certification-test-item/user-certification-test-item.component';
import {
  PreventLeavingAlert,
  PreventLeavingConfirm
} from './guards/prevent-leaving.guard';
import { SkillAreaResumeComponent } from '../user/user-certifications/user-certification/skill-area-resume/skill-area-resume.component';
import { TermsPopupComponent } from '@components/terms-popup/terms-popup.component';
import { ContactUsPopupComponent } from '@components/contact-us-popup/contact-us-popup.component';
import { PrivacyPopupComponent } from '@components/privacy-popup/privacy-popup.component';
import { KjTimeSelectorComponent } from '@kj-controls/kj-time-selector/kj-time-selector.component';
import { TestDateTimeSelectorComponent } from '@components/test-date-time-selector/test-date-time-selector.component';
import { KjValidators, KjValidatorsDirective } from './kj-validators/kj-validators';

@NgModule({
  imports: [CommonModule, SharedModule, ReactiveFormsModule, RouterModule],
  declarations: [
    KJInputComponent,
    KjErrorComponent,
    KjTimeSelectorComponent,
    KjValidatorsDirective,
    TestDateTimeSelectorComponent,
    LanguageToggleComponent,
    TermsPopupComponent,
    ContactUsPopupComponent,
    PrivacyPopupComponent,
    PopupSupportComponent,
    BoxComponent,
    BoxBodyComponent,
    BoxDividerComponent,
    BoxHeaderComponent,
    UserCertificationTestItemComponent,
    ThirdPartyEmailsComponent,
    EditRowComponent,
    CompletedCertificationDetailsComponent,
    SkillAreaResumeComponent
  ],
  providers: [PreventLeavingAlert, PreventLeavingConfirm],
  entryComponents: [
    TermsPopupComponent,
    ContactUsPopupComponent,
    PrivacyPopupComponent
  ],
  exports: [
    KJInputComponent,
    KjErrorComponent,
    KjTimeSelectorComponent,
    KjValidatorsDirective,
    TestDateTimeSelectorComponent,
    LanguageToggleComponent,
    PopupSupportComponent,
    BoxComponent,
    BoxBodyComponent,
    BoxDividerComponent,
    BoxHeaderComponent,
    UserCertificationTestItemComponent,
    ThirdPartyEmailsComponent,
    EditRowComponent,
    CompletedCertificationDetailsComponent,
    SkillAreaResumeComponent
  ]
})
export class AppComponentsModule {}
