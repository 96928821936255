import { RouterModule, Routes } from '@angular/router';

import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { AuthGuard } from './auth/auth.guard';
import { Roles } from '@models/roles';

const routes: Routes = [
  {
    path: 'dashboard',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      }
    ],
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      roles: [Roles.evaluator, Roles.thirdParty, Roles.candidate, Roles.admin]
    }
  },
  {
    path: 'exam/:examId',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './exam/exam.module#ExamModule'
      }
    ],
    canLoad: [AuthGuard],
    data: {
      roles: [Roles.admin, Roles.candidate]
    }
  },
  {
    path: 'video',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './video-conference/video-conference.module#VideoConferenceModule'
      }
    ],
    canLoad: [AuthGuard],
    data: {
      roles: [Roles.evaluator, Roles.admin, Roles.candidate]
    }
  },
  {
    path: 'profile',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './user/user.module#UserModule'
      }
    ],
    canLoad: [AuthGuard],
    data: {
      roles: [Roles.evaluator, Roles.admin, Roles.candidate, Roles.thirdParty]
    }
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
    canLoad: [AuthGuard],
    data: {
      roles: [Roles.evaluator, Roles.admin, Roles.thirdParty]
    }
  },
  {
    path: 'third-party',
    loadChildren: './third-party/third-party.module#ThirdPartyModule',
    canLoad: [AuthGuard],
    data: {
      roles: [Roles.admin, Roles.thirdParty]
    }
  },
  {
    path: 'evaluator',
    loadChildren: './evaluator/evaluator.module#EvaluatorModule',
    canLoad: [AuthGuard],
    data: {
      roles: [Roles.admin, Roles.evaluator]
    }
  },
  {
    path: 'register/certification',
    loadChildren: './register-certification/register-certification.module#RegisterCertificationModule',
    canLoad: [AuthGuard],
    data: {
      roles: [Roles.admin, Roles.candidate]
    }
  },

  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

export const AppRoutes = RouterModule.forRoot(routes, {
  enableTracing: false // <-- debugging purposes only
});
