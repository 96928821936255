import { Component } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ForgotPasswordPopupComponent } from './forgot-password-popup/forgot-password-popup.component';
import { NotificationService } from '@services/notification.service';
import { UserLoginModel } from '@models/user-login-model';
import { LocalizationService } from '@services/localization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  userLoginModel: UserLoginModel;
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required]);

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private localizationService: LocalizationService,
    public dialog: MatDialog,
    public notificationService: NotificationService
  ) {
    this.loginForm = this.formBuilder.group({
      email: this.email,
      password: this.password
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.userLoginModel = Object.assign({}, this.loginForm.value);

      this.loginForm.disable();

      this.authService.login(this.userLoginModel).subscribe(
        () => {
          this.localizationService
            .translate('Notifications.LoginSuccessful')
            .subscribe(successMessage => {
              this.notificationService.successSnackBar(successMessage);
              console.log(successMessage);
            });
          this.loginForm.enable();
        },
        error => {
          this.notificationService.showErrors(error);
          this.loginForm.enable();
        },
        () => {
          this.router.navigate(['/dashboard']);
        }
      );
    }
  }

  openDialog(): void {
    this.dialog.open(ForgotPasswordPopupComponent,{width: "650px"});
  }
}
