import { Component, OnInit, Input } from '@angular/core';
import { UserCertificationModel } from '@models/user-certification-model';
import { Scores } from '@services/scores';
import { MatDialog } from '@angular/material';
import { ReceiveCertificationPopupComponent } from '@app/dashboard/receive-certification-popup/receive-certification-popup.component';

@Component({
  selector: 'app-completed-certification-details',
  templateUrl: './completed-certification-details.component.html',
  styleUrls: ['./completed-certification-details.component.scss']
})
export class CompletedCertificationDetailsComponent implements OnInit {
  @Input() certification: UserCertificationModel;
  @Input() showCompletedCertificatonOptions: boolean;

  constructor(public dialog: MatDialog, public scores: Scores) {}

  ngOnInit() {}

  openReceiveDialog(): void {
    this.dialog.open(ReceiveCertificationPopupComponent);
  }
}
