import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subscription } from 'rxjs';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {
  private subs: Subscription;

  constructor(updates: SwUpdate, private snackbar: MatSnackBar, private languageService: LocalizationService) {

    // Get notified of new deployments.
    updates.available.subscribe(evt => {
      // We're using a snackbar to display the message.  If other snackbars are displayed for other reasons
      // those will overwrite this one.  So we'll remember that there is an update and check on regular intervals
      // to see if we need to continue to show this message.
      localStorage.setItem('update-available', 'true');
      this.showUpdateSnack();

      // Every 30 seconds check if notification needs to be shown again
      if (this.subs && !this.subs.closed) this.subs.unsubscribe();
      this.subs = interval(30000).subscribe(() => {
        this.showUpdateSnack();
      });
    });
  }

  showUpdateSnack() {
    if (localStorage.getItem('update-available') === 'true') {
      const snack = this.snackbar.open(
        this.languageService.instant('Global.UpdateAvailable'),
        this.languageService.instant('Global.Reload')
      );

      snack.onAction().subscribe(() => {
        this.subs.unsubscribe();
        localStorage.setItem('update-available', 'false');
        window.location.reload();
      });

    }
  }
}
