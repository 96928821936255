import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subscriber } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { date } from './date.service';
import { AuthService } from './auth.service';
import { NotificationLogModel } from '@models/notification-log-model';
import { SearchOptions } from '@models/search-options';
import { TriggerTypeModel } from '@models/trigger-type-model';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationLogService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('BASE_URL') private baseUrl: string,
    private localizationService: LocalizationService
  ) {
    this.apiUrl = `${this.baseUrl}api/user/notifications`;
  }

  getNotificationLog(id: string) {
    return new Observable<NotificationLogModel>(subscriber => {
      this.getNotificationLogCall(id, subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs =>
        this.getNotificationLogCall(id, obs)
      );
    });
  }
  private getNotificationLogCall(
    id: string,
    subscriber: Subscriber<NotificationLogModel>
  ) {
    return this.http
      .get<NotificationLogModel>(`${this.apiUrl}/log/${id}`)
      .subscribe(
        nots => subscriber.next(nots),
        error => subscriber.error(error)
      );
  }

  getNotificationLogs() {
    return new Observable<NotificationLogModel[]>(subscriber => {
      this.getNotificationLogsCall(subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs =>
        this.getNotificationLogsCall(obs)
      );
    });
  }
  private getNotificationLogsCall(
    subscriber: Subscriber<NotificationLogModel[]>
  ) {
    this.http
      .get<NotificationLogModel[]>(`${this.apiUrl}/logs`)
      .pipe(
        tap(n => date(n, 'dateRead', 'createdDate')),
        tap(n => n.sort(this.sortDescending))
      )
      .subscribe(
        NotificationLogs => subscriber.next(NotificationLogs),
        error => subscriber.error(error)
      );
  }

  private sortDescending(a: NotificationLogModel, b: NotificationLogModel) {
    return a.createdDate.getTime() > b.createdDate.getTime() ? -1 : 1;
  }

  markRead(nl: NotificationLogModel) {
    return this.http.put<string>(`${this.apiUrl}/markRead`, nl);
  }
  getUnreadCount() {
    return this.http.get<number>(`${this.apiUrl}/unreadCount`);
  }
}
