import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ErrorType } from '@models/error-type';
import { ConfirmDialogComponent } from '@shared/notification/confirm-dialog/confirm-dialog.component';
import { DialogOptions } from '@models/dialog-options';
import { SuccessComponent } from '@shared/notification/success-snackbar/success.component';
import { ErrorComponent } from '@shared/notification/error-snackbar/error.component';
import { ErrorDialogComponent } from '@shared/notification/error-dialog/error-dialog.component';
import { PartialObserver } from 'rxjs';
import { LocalizationService } from './localization.service';
import { RequestResult } from '@models/request-result';
import { OkDialogComponent } from '@shared/notification/ok-dialog/ok-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private dialogOptionDefaults: DialogOptions = {
    data: null
  };

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private localizationService: LocalizationService
  ) {}

  /**
   * Displays a success message along the bottom of the screen to let the user know that some action succeeded.
   * @param messageKey The key from the language file to use for the message.
   * @param duration The number of milliseconds the notification will be displayed for.  Default: 4000.
   */
  successSnackBarKey(messageKey: string, duration = 4000) {
    this.localizationService.translate(messageKey).subscribe({
      next: message => this.successSnackBar(message, duration)
    });
  }

  /**
   * Show a small popup at the bottom of the screen with a short message for an amount of time.
   * @param message The text of the message that will be displayed.
   * @param duration The amount of time in ms the message will be shown.  Default: 4000.
   */
  successSnackBar(message: string, duration = 4000) {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration,
      data: {
        message
      }
    });
  }
  errorSnackBar(message: string) {
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 4000,
      data: {
        message: [message]
      }
    });
  }
  confirmSnackBar(message: string) {
    this.snackBar.openFromComponent(SuccessComponent, {
      verticalPosition: 'top',
      horizontalPosition: 'left',
      panelClass: 'confirmSnackBar',
      data: {
        message: message,
        confirm: true
      }
    });
  }

  openSnackBar(message: string[], component: any) {
    this.snackBar.openFromComponent(component, {
      duration: 4000,
      data: {
        message: message
      }
    });
  }

  errorDialogKey(messageKey, errorType: ErrorType = ErrorType.SYSTEM) {
    this.localizationService.translate(messageKey).subscribe(message => {
      this.errorDialog([message], errorType)
    })
  }

  errorDialog(message: string[], errorType: ErrorType = ErrorType.SYSTEM) {
    if (errorType === ErrorType.VALIDATION) {
      this.openSnackBar(message, ErrorComponent);
    }

    if (errorType === ErrorType.SYSTEM) {
      const options = Object.assign(this.dialogOptionDefaults, {
        data: { message: message }
      });
      this.dialog.open(ErrorDialogComponent, options);
    }
  }

  /**
   * Shows a Confirm Dialog.  That is, a popup message with a "Confirm" and "Cancel" buttons that can be used to ask
   * the user if they are sure they'd like to continue with an action.  Example use:
   * ```
   *     this.notificationService.confirmDialog({
   *        width: '400px',
   *        data: {
   *          messageKey: 'Notifications.ConfirmRemoveMessage'
   *          params: {
   *             testName: selectedTest.title,
   *             userName: `${firstName} ${lastName}`
   *          }
   *        }
   *      })
   *      .afterClosed()
   *      .subscribe(isConfirmed => {
   *        if (isConfirmed) {
   *          this.store.deleteCertification(certificationID);
   *        }
   *      });
   *    }
   *
   * ```
   * The `key` parameters will be used to look up values in the currently selected language.
   * The dialog will look for the following parameters in `data`:
   * - titleKey: The key for the dialog title.
   * - titleText: The string to display as the title.
   * - messageKey: The key for the body of the dialog.
   * - message: the string that will be displayed in the body of the dialog.
   * - confirmText: The string that will be displayed on the `Confirm` button, "Confirm" by default.
   * - cancelText: The string that will be displayed on the `Cancel` button, "Cancel" by default.
   * - params: These are optional parameters that will be used with the keys if the localized strings have parameters in them.
   *
   * At the very least `data.message` or `data.messageKey` must be defined.
   * @param options The options for the dialog.
   */
  confirmDialog(options: DialogOptions) {
    options = Object.assign(this.dialogOptionDefaults, options);
    return this.dialog.open(ConfirmDialogComponent, options);
  }

  okDialog(options: DialogOptions) {
    options = Object.assign(this.dialogOptionDefaults, options);
    return this.dialog.open(OkDialogComponent, options);
  }

  showErrors(error: any) {
    const err = <RequestResult>error.error;
    const messages: string[] = [];

    for (let i = 0; i < err.errors.length; i++) {
      messages.push(err.errors[i].message);
    }

    this.errorDialog(messages, err.type);
  }

  toastSuccessFailObserver<T>(successKey: string): PartialObserver<T> {
    return {
      next: _ =>
        this.localizationService.translate(`Notifications.${successKey}`).subscribe({
          next: message => this.successSnackBar(message)
        }),
      error: error => this.showErrors(error)
    };
  }
}
