import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us-popup',
  templateUrl: './contact-us-popup.component.html'
})
export class ContactUsPopupComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
