import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsPopupComponent } from '@components/contact-us-popup/contact-us-popup.component';
import { PrivacyPopupComponent } from '@components/privacy-popup/privacy-popup.component';
import { TermsPopupComponent } from '@components/terms-popup/terms-popup.component';

@Injectable({
  providedIn: 'root'
})
export class CommonDialogService {
  constructor(public dialog: MatDialog) {}

  showTerms() {
    this.dialog.open(TermsPopupComponent, { width: '80%' });
  }

  showPrivacy() {
    this.dialog.open(PrivacyPopupComponent, { width: '80%' });
  }

  showContact() {
    this.dialog.open(ContactUsPopupComponent);
  }

}
