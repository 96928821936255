import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { AuthService } from '@services/auth.service';
import { LocalizationService } from '@services/localization.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SiteConfigService } from '@services/siteConfig.service';
import { SiteConfigModel } from '@models/site-config-model';

@Component({
  selector: 'app-forgot-password-popup',
  templateUrl: './forgot-password-popup.component.html'
})
export class ForgotPasswordPopupComponent implements OnInit {
  forgotPassForm: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);
  siteConfig: SiteConfigModel;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private locale: LocalizationService,
    public notificationService: NotificationService,
    private dialogRef: MatDialogRef<ForgotPasswordPopupComponent>,
    private siteConfigService: SiteConfigService
  ) {
    this.forgotPassForm = this.formBuilder.group({
      email: this.email
    });
  }
  ngOnInit(): void {
    this.siteConfigService.getSiteConfig().subscribe(siteConfig => {
      this.siteConfig = siteConfig;
    });
  }

  submit() {
    if (this.forgotPassForm.valid) {
      this.forgotPassForm.disable();
      this.authService
        .forgotPassword(encodeURIComponent(this.forgotPassForm.value.email), this.locale.getLocale())
        .subscribe(res => {
          this.dialogRef.close();
        });
    }
  }
}
