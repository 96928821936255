import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: '[app-box-header]',
  templateUrl: './box-header.component.html',
  styleUrls: ['./box-header.component.scss']
})
export class BoxHeaderComponent {
  @Input() class = '';

  constructor() {}

  @HostBinding('class')
  get hostClasses(): string {
    return ['box-header', this.class].join(' ');
  }
}
