import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: '[edit-row]',
  templateUrl: './edit-row.component.html',
  styleUrls: ['./edit-row.component.scss']
})
export class EditRowComponent {
  @Input() form: FormGroup;
  @Output() cancel = new EventEmitter();
  @Input() titleData;
}
