import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';
import { isMoment } from 'moment';

@Directive({
  selector: '[minDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: KjValidatorsDirective,
      multi: true
    }
  ]
})
export class KjValidatorsDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return KjValidators.futureDateValidator(control);
  }
}

export class KjValidators {
  /**
   * Test whether the control's value is a positive whole number
   * @param control The control to test
   * @returns an error if the value of the control is not numeric
   */
  static positiveWholeNumberValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value === null) return null;
    if (/^[0-9]*$/.test(control.value)) return null;
    return { positiveWholeNumber: { actualValue: control.value } };
  }

  /**
   * The value of this date control must be in the future.
   * @param control The form control
   * @returns
   */
  static futureDateValidator(control: AbstractControl): any | null {
    return KjValidators.minDateValidator(new Date())(control);
  }

  /**
   * The value of this date control must be equal to or larger than minDate.
   * @param minDate The smallest allowable date. Default is now.
   * @returns
   */
  static minDateValidator(minDate: Date = new Date()): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value: Date = isMoment(control.value) ? control.value.toDate() : control.value;
      if (value === null) return null;
      if (!(value instanceof Date)) return null;
      const isGood = value.getTime() >= minDate.getTime();
      return isGood ? null : { minDate: { value, minDate } };
    };
  }
}
