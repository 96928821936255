import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SidebarLayoutComponent } from './sidebar-layout/sidebar-layout.component';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { AppComponentsModule } from '@shared/app-components.module';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule, AppComponentsModule],
  exports: [MainLayoutComponent],
  declarations: [
    MainLayoutComponent,
    SidebarLayoutComponent,
    HeaderLayoutComponent
  ]
})
export class LayoutModule {}
