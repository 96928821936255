import { Injectable } from '@angular/core';

const SCORES = {
  'en-CA': ['X', 'LE1-', 'LE1=', 'LE1+', 'LE2-', 'LE2=', 'LE2+', 'LE3-', 'LE3=', 'LE3+', 'LE4-', 'LE4='],
  'fr-CA': ['X', 'FJ1-', 'FJ1=', 'FJ1+', 'FJ2-', 'FJ2=', 'FJ2+', 'FJ3-', 'FJ3=', 'FJ3+', 'FJ4-', 'FJ4=']
};

@Injectable({
  providedIn: 'root'
})
export class Scores {
  thumb(language: string) {
    return (score: number) => {
      return SCORES[language || 'en-CA'][score];
    };
  }

  label(score: number, language: string) {
    if (!language) {
      return '';
    }
    return SCORES[language][score];
  }
}
