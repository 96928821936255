import {
  Pipe,
  PipeTransform,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';

@Pipe({
  name: 'tminus',
  pure: false
})
export class TMinusPipe implements PipeTransform, OnDestroy {
  private static readonly SECOND = 1000;
  private static readonly MINUTE = 60 * TMinusPipe.SECOND;
  private static readonly HOUR = 60 * TMinusPipe.MINUTE;
  private static readonly DAY = 24 * TMinusPipe.HOUR;
  private static readonly PAD = '0000000000';

  private timer: NodeJS.Timeout;

  constructor(private ref: ChangeDetectorRef) {}

  transform(value: any, format: string = 'DAYS', target: Date = null): any {
    if (!target) {
      target = new Date();
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.ref.markForCheck();
        }, 1000);
      }
    } else if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    switch (format.toUpperCase()) {
      case 'DAYS':
      case 'HOURS':
      case 'MINUTES':
      case 'SECONDS':
        format = format.substr(0, 1).toUpperCase();
        break;
    }

    const date = new Date(value);
    const time = date.getTime() - target.getTime();
    const symbols = format.split('"');
    let res = '';
    let str = false;
    symbols.forEach((s, i) => {
      if (str) {
        res += s;
      } else if (s === '' && i > 0 && i < symbols.length - 1) {
        res += '"';
      } else {
        res += s.replace(/([DHMS])\1*/gi, (match, p1) => {
          const l = match.length;
          switch (p1) {
            case 'D':
            case 'd':
              return this.reduce(time, l, TMinusPipe.DAY);
            case 'H':
              return this.reduce(time, l, TMinusPipe.HOUR);
            case 'M':
              return this.reduce(time, l, TMinusPipe.MINUTE);
            case 'S':
              return this.reduce(time, l, TMinusPipe.SECOND);
            case 'h':
              return this.reduce(time, l, TMinusPipe.HOUR, TMinusPipe.DAY);
            case 'm':
              return this.reduce(time, l, TMinusPipe.MINUTE, TMinusPipe.HOUR);
            case 's':
              return this.reduce(time, l, TMinusPipe.SECOND, TMinusPipe.MINUTE);
            default:
              return match;
          }
        });
      }
      str = !str;
    });
    return res;
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  private reduce(
    number: number,
    length: number,
    into: number,
    exclude: number = 0
  ): string {
    if (exclude > 0) {
      number %= exclude;
    }
    const res = Math.max(Math.floor(number / into), 0).toString();
    if (res.length < length) {
      return (TMinusPipe.PAD + res).substr(-length);
    }
    return res;
  }
}
