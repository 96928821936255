import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LocalizationService } from '@services/localization.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  defaults: any;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private localizationService: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.localizationService.translate('Global').subscribe(result => {
      this.defaults = {
        titleText: result.Confirm,
        cancelText: result.Cancel,
        confirmText: result.Confirm
      };
      this.data = Object.assign(this.defaults, this.data);
    });
  }
}
