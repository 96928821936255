import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { AuthService } from '@services/auth.service';
import { LocalizationService } from '@services/localization.service';

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})
export class HeaderLayoutComponent implements OnInit {
  @Output() openSidebar: EventEmitter<void> = new EventEmitter();
  private leaveMessage: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    public notificationService: NotificationService,
    local: LocalizationService
  ) {
    local.translate('Exam.Stay').subscribe(t => (this.leaveMessage = t));
  }

  ngOnInit() {}

  logout() {
    if (this.authService.examInProgress) {
      alert(this.leaveMessage);
      return;
    }
    this.authService.logout().subscribe(
      response => {
        this.notificationService.successSnackBar(
          response['logoutMessage'].value
        );
      },
      error => {
        this.notificationService.showErrors(error);
        console.log(error['logoutMessage'].value);
      },
      () => {
        this.router.navigate(['/login']);
      }
    );
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  navigateToProfile() {
    this.router.navigate(['/profile']);
  }

  openSideMenu() {
    this.openSidebar.emit();
  }
}
