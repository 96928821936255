import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { date } from './date.service';
import { EvaluatorAvailabilityTimeModel } from '@models/evaluator-availability-time-model';
import { EvaluatorAssignmentModel } from '@models/evaluator-assignment-model';
import { TestScheduleModel } from '@models/test-schedule-model';
import { AvailabilityAndAssignmentsModel } from '@models/availability-and-assignments-model';
import { EvaluatorModel } from '@models/evaluator-model';
import { UserCertificationTestModel } from '@models/user-certification-test-model';
import { EvaluatorUserTestDetailsModel } from '@models/evaluator-user-test-details-model';

@Injectable({
  providedIn: 'root'
})
export class EvaluatorService {
  private apiUrl: string;
  private apiUrlSuffix: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.apiUrl = `${this.baseUrl}api/evaluators`;
  }

  private createGetParams(options: any) {
    const params = {};
    Object.keys(options)
      .filter(x => options[x] !== null)
      .forEach(x => {
        params[x] = options[x].toString();
      });
    return params;
  }

  // Evaluator Availability
  private convertDates(event) {
    return date(
      event,
      'availability',
      'start',
      'end',
      'repeatGroup',
      'repeatEnd',
      'assignments',
      'completedDate',
      'evaluationDueDate',
      'scheduledEndDate',
      'scheduledStartDate'
    );
  }

  createEvaluatorAvailabilityTime(event: EvaluatorAvailabilityTimeModel, isReturnAll = false) {
    return this.http
      .post<EvaluatorAvailabilityTimeModel[]>(`${this.apiUrl}/${event.evaluatorId}?isReturnAll=${isReturnAll}`, event)
      .pipe(tap(e => this.convertDates(e)));
  }

  updateEvaluatorAvailabilityTime(event: EvaluatorAvailabilityTimeModel, isReturnAll = false) {
    return this.http
      .put<EvaluatorAvailabilityTimeModel[]>(`${this.apiUrl}/${event.id}?isReturnAll=${isReturnAll}`, event)
      .pipe(tap(e => this.convertDates(e)));
  }

  deleteEvaluatorAvailabilityTime(event: EvaluatorAvailabilityTimeModel, isReturnAll = false) {
    return this.http
      .delete<EvaluatorAvailabilityTimeModel[]>(
        `${this.apiUrl}/${event.evaluatorId}/${event.id}?scope=${
          event.isUpdateFollowing ? 'following' : 'justThis'
        }&isReturnAll=${isReturnAll}`
      )
      .pipe(tap(x => this.convertDates(x)));
  }

  getEvaluatorAvailabilityAndAssignments(evaluatorId: string, month: Date) {
    return this.http
      .get<AvailabilityAndAssignmentsModel>(`${this.apiUrl}/availabilityAndAssignments/${evaluatorId || ''}`, {
        params: {
          date: month.toISOString()
        }
      })
      .pipe(tap(x => this.convertDates(x)));
  }

  // Assigned Tests
  getAssignedTests(evaluatorId: string) {
    return this.http
      .get<EvaluatorAssignmentModel[]>(`${this.apiUrl}/${evaluatorId}/assignments`)
      .pipe(tap(x => date(x, 'scheduledStartDate', 'scheduledEndDate', 'completedDate', 'evaluationDueDate')));
  }

  // Registration - Schedule Test
  getTestScheduleOptionsForDay(
    userTestId: string,
    selectedDate: Date,
    inEvaluatorsRequired: number = null,
    inDurationMinutes: number = null
  ) {
    return this.http
      .get<TestScheduleModel>(`${this.apiUrl}/testScheduleOptionsForDay/${userTestId}`, {
        params: this.createGetParams({
          inEvaluatorsRequired,
          inDurationMinutes,
          date: selectedDate.toISOString()
        })
      })
      .pipe(tap(x => date(x, 'scheduledStartTime', 'startTimesWithEvaluators', 'startTime')));
  }

  getTestScheduleOptions(userTestId: string[]) {
    return this.http
      .get<TestScheduleModel>(`${this.apiUrl}/testScheduleOptions/${userTestId}`)
      .pipe(tap(x => date(x, 'scheduledStartTime', 'startTimes')));
  }

  getAvailableDays(userTestId: string, inEvaluatorsRequired: number = null, inDurationMinutes: number = null) {
    return this.http
      .get<string[]>(`${this.apiUrl}/availableDays/${userTestId}`, {
        params: this.createGetParams({
          startOfToday: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
          inEvaluatorsRequired,
          inDurationMinutes
        })
      })
      .pipe(map(x => x.map(d => (d === null ? null : new Date(new Date(d).setHours(0, 0, 0, 0))))));
  }

  /**
   * Temporarily blocks the date and time so that other people cannot select it.  It will be finalized after payment.
   * @param userTestId
   * @param selectedDateTime
   */
  blockTime(userTestId: string, selectedDateTime: Date) {
    return this.http.post(`${this.apiUrl}/blockTime/${userTestId}`, selectedDateTime);
  }
  unBlockTime(userTestId: string) {
    return this.http.delete(`${this.apiUrl}/unBlockTime/${userTestId}`);
  }

  getEvaluators() {
    return this.http.get<EvaluatorModel[]>(`${this.apiUrl}`);
  }

  getUncompletedTests() {
    return this.http
      .get<EvaluatorAssignmentModel[]>(`${this.apiUrl}/uncompletedTests`)
      .pipe(tap(x => date(x, 'assignedTo', 'expiryDate')));
  }

  getUserTestDetails(userTestId: string) {
    return this.http
      .get<EvaluatorUserTestDetailsModel>(`${this.apiUrl}/userTestDetails/${userTestId}`)
      .pipe(tap(x => date(x, 'scheduledStartTime', 'scheduledEndTime')));
  }

  updateUserTestDetails(userTestDetails: EvaluatorUserTestDetailsModel) {
    return this.http
      .post<EvaluatorUserTestDetailsModel>(
        `${this.apiUrl}/userTestDetails/${userTestDetails.userTestId}`,
        userTestDetails
      )
      .pipe(tap(x => date(x, 'scheduledStartTime', 'scheduledEndTime')));
  }

  getAvailableEvaluators(userTestId: string, isObserver: boolean) {
    return this.http.get<EvaluatorModel[]>(`${this.apiUrl}/availableEvaluators/${userTestId}?isObserver=${isObserver}`);
  }

  searchAvailableEvaluators(
    userTestId: string,
    searchCriteria: string,
    isObserver: boolean,
    startDate: Date,
    durationMinutes: number
  ) {
    return this.http.get<EvaluatorModel[]>(`${this.apiUrl}/searchAvailableEvaluators/${userTestId}`, {
      params: this.createGetParams({
        searchCriteria,
        isObserver: isObserver.toString(),
        startDate: startDate ? startDate.toISOString() : null,
        durationMinutes: durationMinutes.toString()
      })
    });
  }

  // Don't think these are needed anymore
  // assignEvaluator(userTestId: string, evaluatorId: string, isObserver: boolean) {
  //   return this.http.post<EvaluatorModel[]>(
  //     `${this.apiUrl}/assignEvaluator/${userTestId}/${evaluatorId}?isObserver=${isObserver}`,
  //     {}
  //   );
  // }
  // unassignEvaluator(userTestId: string, evaluatorId: string) {
  //   return this.http.delete<EvaluatorModel[]>(`${this.apiUrl}/assignEvaluator/${userTestId}/${evaluatorId}`);
  // }
}
