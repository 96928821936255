import { Injectable, Inject } from '@angular/core';
import { TitleModel } from './../_models/title-model';
import { Subscriber, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  apiUrl: string;

  static cacheTitles: any = {};

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private localizationService: LocalizationService
  ) {
    this.apiUrl = `${this.baseUrl}api/titles`;
  }

  getTitles() {
    return new Observable<TitleModel[]>(subscriber => {
      this.getTitlesCall(subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs => this.getTitlesCall(obs));
    });
  }

  private getTitlesCall(subscriber: Subscriber<TitleModel[]>) {
    const code = this.localizationService.getLocale();

    if (TitleService.cacheTitles[code]) {
      subscriber.next(TitleService.cacheTitles[code]);
      return;
    }

    this.http.get<TitleModel[]>(`${this.apiUrl}`).subscribe(
      titles => {
        TitleService.cacheTitles[code] = titles.sort(this.sortOtherLast);
        subscriber.next(TitleService.cacheTitles[code]);
      },
      error => subscriber.error(error)
    );
  }

  // Make sure 'Other' is always at the end of the list no matter the language.
  private sortOtherLast(a, b) {
    if (a.type === 'Other') return 1;
    else if (b.type === 'Other') return -1;
    else return a.label < b.label ? -1 : 1;
  }
}
