import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LocalizationService } from '@services/localization.service';
import { UserNotificationService } from '@services/user-notification.service';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  isWideCard = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userNotificationService: UserNotificationService
  ) {
    // if we got a logId then we have a notificationLog to mark as read
    const logId = activatedRoute.snapshot.queryParams['logId'];
    if (logId) {
      userNotificationService.markRead(logId).subscribe();
    }
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        switchMap(route => route.firstChild.data)
      )
      .subscribe(data => {
        this.isWideCard = !!data.wideCard;
      });
  }

  ngOnInit(): void {}
}
