import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageModel } from '@models/language-model';
import { Cacheable } from 'ngx-cacheable';
import { KnownLanguageModel } from '@models/known-language-model';
import { Subscriber, Observable } from 'rxjs';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  apiUrl: string;
  CACHE_KEY = 'Languages';

  static cache: any = {};

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private localizationService: LocalizationService
  ) {
    this.apiUrl = `${this.baseUrl}api/Language`;
  }

  @Cacheable()
  getLanguages() {
    return this.http.get<LanguageModel[]>(`${this.apiUrl}`);
  }

  getKnownLanguages() {
    return new Observable<KnownLanguageModel[]>(subscriber => {
      this.getKnownLanguagesCall(subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs => this.getKnownLanguagesCall(obs));
    });
  }

  private getKnownLanguagesCall(subscriber: Subscriber<KnownLanguageModel[]>) {
    const code = this.localizationService.getLocale();

    if (LanguageService.cache[code]) {
      subscriber.next(LanguageService.cache[code]);
      return;
    }

    this.http.get<KnownLanguageModel[]>(`${this.apiUrl}/known`).subscribe(
      languages => {
        LanguageService.cache[code] = languages;
        subscriber.next(languages);
      },
      error => subscriber.error(error)
    );
  }
}
