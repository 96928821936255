import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
// Material Modules
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatFormFieldModule
} from '@angular/material';

import { PreloaderDirective } from '@directives/preloader.directive';
import { ConfirmEqualValidationDirective } from '@directives/confirm-equal-validator.directive';
import { HasRoleDirective } from '@directives/has-role.directive';
import { TMinusPipe } from '@pipes/tminus.pipe';
import { ErrorComponent } from './notification/error-snackbar/error.component';
import { ErrorDialogComponent } from './notification/error-dialog/error-dialog.component';
import { SuccessComponent } from './notification/success-snackbar/success.component';
import { ConfirmDialogComponent } from './notification/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { AutoFocusDirective } from '@directives/auto-focus.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FileUploadModule } from 'ng2-file-upload';
import { SafeUrlPipe } from '@pipes/safe-url.pipe';
import { GetTranslationPipe } from '@pipes/gettranslation.pipe';
import { SafeHtmlPipe } from '@pipes/safe-html.pipe';
import { KortoDatePipe } from '@pipes/korto-date.pipe';
import { KortoDateTimePipe } from '@pipes/korto-date-time.pipe';
import { CommunicationPopupComponent } from '@components/communication-popup/communication-popup.component';
import { TimeSpanPipe } from '@pipes/time-span.pipe';
import { OkDialogComponent } from './notification/ok-dialog/ok-dialog.component';
import { KortoTimePipe } from '@pipes/korto-time.pipe';
import { KortoDateLongPipe } from '@pipes/korto-date-long.pipe';
import { KortoTimeShortPipe } from '@pipes/korto-time-short.pipe';

@NgModule({
  declarations: [
    // Directives.
    PreloaderDirective,
    ConfirmEqualValidationDirective,
    HasRoleDirective,
    AutoFocusDirective,
    // Pipes.
    TMinusPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    GetTranslationPipe,
    KortoDatePipe,
    KortoDateLongPipe,
    KortoDateTimePipe,
    KortoTimePipe,
    KortoTimeShortPipe,
    TimeSpanPipe,
    // Notifications
    ErrorComponent,
    ErrorDialogComponent,
    SuccessComponent,
    ConfirmDialogComponent,
    OkDialogComponent,
    CommunicationPopupComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    // Material Modules
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,

    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    AngularEditorModule,
    FileUploadModule
  ],
  exports: [
    TranslateModule,
    // Directives.
    PreloaderDirective,
    ConfirmEqualValidationDirective,
    HasRoleDirective,
    AutoFocusDirective,
    // Pipes.
    TMinusPipe,
    SafeUrlPipe,
    GetTranslationPipe,
    SafeHtmlPipe,
    KortoDatePipe,
    KortoDateLongPipe,
    KortoDateTimePipe,
    KortoTimePipe,
    KortoTimeShortPipe,
    TimeSpanPipe,
    // Material Modules
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,

    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    AngularEditorModule,
    FileUploadModule,
    CommunicationPopupComponent
  ],
  entryComponents: [
    ErrorComponent,
    SuccessComponent,
    ErrorDialogComponent,
    ConfirmDialogComponent,
    OkDialogComponent,
    CommunicationPopupComponent
  ]
})
export class SharedModule {}
