import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kortoTimeShort'
})
export class KortoTimeShortPipe extends DatePipe implements PipeTransform {
  transform(dateValue: any, args?: any): any {
    if (dateValue === undefined) return '';
    const d = super.transform(dateValue, 'h:mm:a').split(':');
    const h = d[0];
    const m = parseInt(d[1]);
    const a = d[2][0].toLowerCase();
    return `${h}${m ? ':' + m.toString().padStart(2, '0') : ''}${a}`;
  }
}
