import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login/login.component';
import { AuthRoutes } from './auth.routing';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordPopupComponent } from './login/forgot-password-popup/forgot-password-popup.component';

import { SharedModule } from '@shared/shared.module';
import { AppComponentsModule } from '@shared/app-components.module';
import { AuthCardComponent } from './auth-card/auth-card.component';
import { AuthCardFooterComponent } from './auth-card/auth-card-footer/auth-card-footer.component';
import { AuthCardHeaderComponent } from './auth-card/auth-card-header/auth-card-header.component';
import { AuthComponent } from './auth.component';
import { DetailsComponent } from './register/details/details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { PersonalDetailsModule } from '@app/user/personal-details/personal-details.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CreateNewPasswordComponent } from './create-new-password/create-new-password.component';
import { UnsupportedBrowserWarningComponent } from './login/unsupported-browser-warning/unsupported-browser-warning.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthRoutes,
    AppComponentsModule,
    SharedModule,
    PersonalDetailsModule,
    MatPasswordStrengthModule
  ],
  entryComponents: [ForgotPasswordPopupComponent],
  declarations: [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    DetailsComponent,
    ForgotPasswordPopupComponent,
    CreateNewPasswordComponent,
    AuthCardComponent,
    AuthCardFooterComponent,
    AuthCardHeaderComponent,
    UnsupportedBrowserWarningComponent
  ]
})
export class AuthModule {}
