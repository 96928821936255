import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UserProfileModel } from '@models/user-profile-model';
import { MatDialog } from '@angular/material';
import { ProfessionNotListedPopupComponent } from './profession-not-listed-popup/profession-not-listed-popup.component';
import { LanguageService } from '@services/language.service';
import { LanguageModel } from '@models/language-model';
import { ProfessionService } from '@services/profession.service';
import { ProfessionModel } from '@models/profession-model';
import { ProvinceService } from '@services/province.service';
import { ProvinceModel } from '@models/province-model';
import { TitleService } from './../../_services/title.service';
import { TitleModel } from '@models/title-model';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { KnownLanguageModel } from '@models/known-language-model';
import { AuthService } from '@services/auth.service';
import { CommonDialogService } from '@services/common-dialog.service';
import { KjValidators } from '@shared/kj-validators/kj-validators';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() buttonText: string;
  @Input() userProfile: UserProfileModel;
  @Input() readonly: boolean = false;
  @Output() submitUserProfile = new EventEmitter<UserProfileModel>();
  @ViewChild('txtTitleOther') txtTitleOther: ElementRef;

  form: FormGroup;
  addressForm: FormGroup;

  languages: LanguageModel[];
  knownLanguages: KnownLanguageModel[];
  professions: ProfessionModel[];
  provinces: ProvinceModel[];
  titles: TitleModel[];
  titleOtherId: string;
  maxDate: Date = new Date();

  destroy$ = new Subject<boolean>();

  maxAge = 100;
  defaultAge = 30;
  yearOfBirthOptions = new Array(this.maxAge).fill(0).map((y, i) => this.maxDate.getFullYear() - this.maxAge + i);
  filteredYearOfBirthOptions$: Observable<number[]>;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public commonDialogService: CommonDialogService,
    private languageService: LanguageService,
    private professionService: ProfessionService,
    private provinceService: ProvinceService,
    private titleService: TitleService,
    public authService: AuthService
  ) {
    this.setupForm();

    this.filteredYearOfBirthOptions$ = this.form.controls.yearOfBirth.valueChanges.pipe(
      startWith(''),
      map(value => this.yearOfBirthOptions.filter(year => year.toString().includes(value)) as number[])
    );
  }

  /**
   * Called when the year of birth selection box opens
   * If the value is null, scroll to the default age.
   * @returns
   */
  yearOfBirthOpen() {
    if (this.form.value.yearOfBirth !== null) return;

    // Get the item to scroll to
    const selItem = document.querySelector(`mat-option.year-of-birth:nth-child(${this.maxAge - this.defaultAge})`);

    selItem.scrollIntoView({
      block: 'center'
    });
  }

  ngOnInit() {
    this.titleService
      .getTitles()
      .pipe(takeUntil(this.destroy$))
      .subscribe((titles: TitleModel[]) => {
        this.titles = titles;
        this.titleOtherId = this.getParam(this.titles, 'Other', 'id', 'type');
      });

    this.languageService.getLanguages().subscribe((languages: LanguageModel[]) => {
      this.languages = languages;
    });

    this.languageService.getKnownLanguages().subscribe(languages => {
      this.knownLanguages = languages;
    });

    this.professionService
      .getProfessions()
      .pipe(takeUntil(this.destroy$))
      .subscribe((professions: ProfessionModel[]) => {
        this.professions = professions;
      });

    this.provinceService
      .getProvinces()
      .pipe(takeUntil(this.destroy$))
      .subscribe((provinces: ProvinceModel[]) => {
        this.provinces = provinces;
        this.addressForm.updateValueAndValidity();
      });
  }

  select(value: string, type: string) {
    setTimeout(_ => this[`txt${type}Other`].nativeElement.focus(), 100);
  }

  clearOther(type: string) {
    this.form.controls[`${type}Id`].setValue(null);
    this.form.controls[`${type}Other`].setValue(null);
  }

  /**
   * Search an array of options by a parameter and return another parameter.
   * @param o The array of options that are available.
   * @param searchFor The value of the parameter to search for.
   * @param returnParam The name of the parameter whose value we want.
   * @param searchParam The name of the parameter to search by.
   */
  getParam(o: any[], searchFor: string, returnParam = 'label', searchParam = 'id') {
    return o && searchFor ? o.find(x => x[searchParam] === searchFor)[returnParam] : null;
  }

  getTitle() {
    return this.getParam(this.titles, this.userProfile.titleId);
  }

  getProvince() {
    return this.getParam(this.provinces, this.userProfile.address.provinceId);
  }

  getProfession() {
    return this.getParam(this.professions, this.userProfile.professionId);
  }

  getCommLanguage() {
    return this.getParam(this.languages, this.userProfile.communicationLanguageId, 'code');
  }

  getMotherTongue() {
    return this.getParam(this.knownLanguages, this.userProfile.maternalLanguageId);
  }

  ngOnChanges(changes: any): void {
    if (changes.userProfile) {
      this.bindForm();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit() {
    if (this.form.invalid && this.authService.getUserId() === this.userProfile.id) {
      this.form.markAsTouched();
      return false;
    }

    const data = {
      ...this.form.value
    } as UserProfileModel;
    data.address.country = 'Canada';
    data.address.postalCode = data.address.postalCode.trim().toUpperCase();

    // prevents unsaved changes guard to be triggered
    this.form.markAsPristine();
    this.addressForm.markAsPristine();

    this.submitUserProfile.emit(data);
  }

  private bindForm() {
    //  Clear the form before patching new values as some values could be missing and the form would keep the previous one.
    this.form.reset();
    this.form.patchValue(this.userProfile);
  }

  setupForm() {
    this.addressForm = this.formBuilder.group(
      {
        streetName: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        provinceId: new FormControl('', Validators.required),
        postalCode: new FormControl('', [Validators.required, this.validatePostalCode])
      },
      {
        validators: this.postalCodeMatchesProvinceValidator
      }
    );

    this.form = this.formBuilder.group(
      {
        id: new FormControl(''),
        titleId: new FormControl('', Validators.required),
        titleOther: new FormControl(''),
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        yearOfBirth: new FormControl('', [
          Validators.required,
          Validators.min(this.maxDate.getFullYear() - this.maxAge),
          Validators.max(this.maxDate.getFullYear()),
          KjValidators.positiveWholeNumberValidator
        ]),
        maternalLanguageId: new FormControl('', Validators.required),
        address: this.addressForm,
        communicationLanguageId: new FormControl('', Validators.required),
        professionId: new FormControl('', Validators.required),
        isAgreedToTOS: new FormControl('', this.validateAgreementToTOS)
      },
      {
        validators: [this.validateOtherFactory('title')]
      }
    );
  }

  // Create a validator function for fields that have an OTHER option.
  validateOtherFactory(type: 'title') {
    return (fg: FormGroup) => {
      if (fg.controls[`${type}Id`].value === this[`${type}OtherId`])
        if (!fg.controls[`${type}Other`].value || !fg.controls[`${type}Other`].value.trim().length) {
          fg.controls[`${type}Other`].setErrors({ empty: true });
          return { [`${type}Empty`]: true };
        }
      return null;
    };
  }

  validateAgreementToTOS(c: FormControl) {
    if (c.value) return null;
    return {
      mustAgreeToTOS: {
        valid: false
      }
    };
  }

  /**
   * Make sure the postal code is in the correct format.
   * @param c The control that we're testing
   */
  validatePostalCode(c: FormControl) {
    if (!c.value) return null;

    const POSTALCODE_EXP = /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ][ -]?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/;
    return POSTALCODE_EXP.test(c.value.trim().toUpperCase())
      ? null
      : {
          validatePostalCode: {
            valid: false
          }
        };
  }

  showPostalCodeWarning() {
    return !this.isPostalProvinceValid(this.addressForm);
  }

  postalCodeMatchesProvinceValidator: ValidatorFn = (addressFormGroup: FormGroup): ValidationErrors | null => {
    if (this.isPostalProvinceValid(addressFormGroup)) return null;
    return { provincePostalCodeMismatch: true };
  };

  /**
   * Make sure the postal code is valid for the selected province.
   * @returns true if the postal code matches the province or both fields are not yet populated.
   * false otherwise.
   */
  isPostalProvinceValid(addressFormGroup: FormGroup): boolean {
    if (this.provinces) {
      /*
        A Newfoundland Labrador
        B Nova Scotia
        C PEI
        E New Brunswick

        G Eastern PQ
        H Montreal PQ
        J Western PQ

        K Eastern Ont
        L Northern GTO Ont
        M Southern GTO Ont
        N Southern Ont
        P Northern Ont

        R Manitoba
        S Sask
        T Alberta
        V BC
        X NWT & Nunavut
        Y Yukon
			*/

      const provincePostalCodeMap = {
        NL: ['A'],
        NS: ['B'],
        PE: ['C'],
        NB: ['E'],
        QC: ['G', 'H', 'J'],
        ON: ['K', 'L', 'M', 'N', 'P'],
        MB: ['R'],
        SK: ['S'],
        AB: ['T'],
        BC: ['V'],
        YT: ['Y'],
        NT: ['X'],
        NU: ['X']
      };

      const prov = this.provinces.find(p => p.id === addressFormGroup.controls.provinceId.value);

      if (prov && prov.shortName && addressFormGroup.controls.postalCode.value) {
        const firstLetter = addressFormGroup.controls.postalCode.value[0].toUpperCase();
        if (provincePostalCodeMap[prov.shortName].includes(firstLetter)) {
          return true;
        } else {
          return false;
        }
      }
    }
    // No provinces, no validation
    return true;
  }

  openDialog(): void {
    this.dialog.open(ProfessionNotListedPopupComponent);
  }
}
