import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Roles } from '@models/roles';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  hasAdminRole = false;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.hasAdminRole = this.authService.roleMatch([Roles.admin]);
  }

  public openSidebar() {
    const div = document.querySelector('.app-sidebar');
    div.classList.add('show');
    document.body.addEventListener('click', this.closeSidebar);
    event.stopPropagation();
  }

  public closeSidebar() {
    const div = document.querySelector('.app-sidebar');
    div.classList.remove('show');
    document.body.removeEventListener('click', this.closeSidebar);
    event.stopPropagation();
  }
}
