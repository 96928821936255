import { Component, OnInit, Input } from '@angular/core';
import { UserCertificationTestModel } from '@models/user-certification-test-model';
import { TestStatus } from '@models/test-status';
import { Scores } from '@services/scores';
import { isFutureDate } from '@services/date.service';

@Component({
  selector: 'app-user-certification-test-item',
  templateUrl: './user-certification-test-item.component.html',
  styleUrls: ['./user-certification-test-item.component.scss'],
  host: { class: 'test-list-item' }
})
export class UserCertificationTestItemComponent implements OnInit {
  @Input() test: UserCertificationTestModel;
  @Input() certificationCompleted = false;
  @Input() certificationPurchased = true;
  @Input() canAccess = false;
  TestStatus = TestStatus;

  // Need to create local variable so it's available in the template.
  isFutureDate = isFutureDate;

  constructor(public scores: Scores) {}

  ngOnInit() {}

  //Show instruction link if is Active || Started only
  canShowTestInstruction() : boolean{
    let canShowInstructions = false;

    if(this.test.status === TestStatus.Active
      || this.test.status === TestStatus.Started){
      canShowInstructions = true;
    }

    return canShowInstructions;
  }
}
