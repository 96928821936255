import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-auth-card',
  templateUrl: './auth-card.component.html'
})
export class AuthCardComponent implements OnInit {
  @Input() wide = false;

  constructor() {}

  ngOnInit() {}
}
