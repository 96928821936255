import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserProfileModel } from '@models/user-profile-model';
import 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.apiUrl = `${this.baseUrl}api/userprofile`;
  }

  getUser(id: string) {
    return this.http.get<UserProfileModel>(`${this.apiUrl}/${id}`);
  }

  putUser(id: string, userProfileModel: UserProfileModel) {
    return this.http.put(`${this.apiUrl}/${id}`, userProfileModel);
  }

  isProvincePostalCodeMismatch(id: string) {
    return this.http.get<boolean>(`${this.apiUrl}/postalCodeMismatch/${id}`);
  }
}
