import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanDeactivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Injectable()
export class PreventLeavingConfirm implements CanDeactivate<CanPreventLeaving> {
  constructor(
    private auth: AuthService,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  canDeactivate(
    component: CanPreventLeaving,
    currentRoute: ActivatedRouteSnapshot
  ) {
    if (!this.auth.loggedIn()) {
      return true;
    }
    if (!component.canLeave()) {
      const confirmation = confirm(component.leaveMessage);
      if (!confirmation) {
        const currentUrlTree = this.router.createUrlTree([], currentRoute);
        const currentUrl = currentUrlTree.toString();
        this.location.go(currentUrl);
      }

      return confirmation;
    }
    return true;
  }
}

@Injectable()
export class PreventLeavingAlert implements CanDeactivate<CanPreventLeaving> {
  constructor(private auth: AuthService) {}

  canDeactivate(component: CanPreventLeaving) {
    if (!this.auth.loggedIn()) {
      return true;
    }
    if (!component.canLeave()) {
      alert(component.leaveMessage);
      return false;
    }
    return true;
  }
}

export interface CanPreventLeaving {
  readonly leaveMessage: string;
  canLeave(): boolean;
}
