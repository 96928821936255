import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { AppComponentsModule } from '@shared/app-components.module';
import { ProfessionNotListedPopupComponent } from './profession-not-listed-popup/profession-not-listed-popup.component';
import { PersonalDetailsComponent } from './personal-details.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppComponentsModule
  ],
  entryComponents: [ProfessionNotListedPopupComponent],
  declarations: [PersonalDetailsComponent, ProfessionNotListedPopupComponent],
  exports: [PersonalDetailsComponent]
})
export class PersonalDetailsModule {}
