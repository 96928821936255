import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: '[app-box-divider]',
  templateUrl: './box-divider.component.html',
  styleUrls: ['./box-divider.component.scss']
})
export class BoxDividerComponent {
  @Input() class = '';

  constructor() {}

  @HostBinding('class')
  get hostClasses(): string {
    return ['box-divider', this.class].join(' ');
  }
}
