import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { UserProfileModel } from '@models/user-profile-model';
import { PersonalDetailsComponent } from '@app/user/personal-details/personal-details.component';
import { UserRegistrationStore } from './user-registration.store';
import { NotificationService } from '@services/notification.service';
import { LocalizationService } from '@services/localization.service';
import { CanPreventLeaving } from '@shared/guards/prevent-leaving.guard';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [UserRegistrationStore]
})
export class DetailsComponent implements OnInit, CanPreventLeaving {
  @ViewChild('personalDetails') personalDetails: PersonalDetailsComponent;
  leaveMessage: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
    public store: UserRegistrationStore,
    local: LocalizationService
  ) {
    local.translate('Register.Unsaved').subscribe(t => (this.leaveMessage = t));
  }

  ngOnInit() {
    this.store.init();
  }

  canLeave(): boolean {
    return (
      this.personalDetails.form.pristine &&
      this.personalDetails.addressForm.pristine
    );
  }

  submitUserProfile(userProfile: UserProfileModel) {
    this.store.submitUserProfile(userProfile).subscribe(
      () => this.submitUserProfileComplete(),
      error => this.notificationService.showErrors(error)
    );
  }

  logout() {
    this.authService.logout().subscribe(
      response => {
        this.notificationService.successSnackBar(
          response['logoutMessage'].value
        );
      },
      error => {
        this.notificationService.showErrors(error);
        console.log(error['logoutMessage'].value);
      },
      () => {
        this.router.navigate(['/login']);
      }
    );
  }

  private submitUserProfileComplete() {
    const user = this.authService.getUser();
    if (
      this.authService.isCurrentUserCandidate() &&
      !user.hasRegisteredForACertification
    ) {
      this.router.navigate(['/register/certification']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
