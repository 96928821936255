import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { LocalizationService } from '@services/localization.service';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss']
})
/**
 * This page allows users to reset/change their own password.  This page can only be reached
 * by a link that is emailed to them when they click the "Forgot Password" link on the login
 * page.
 */
export class CreateNewPasswordComponent implements OnInit, OnDestroy {
  private passwordSubscription: Subscription;
  private email: string;
  private token: string;
  private locale: string;

  // Controls
  form: FormGroup;
  password = new FormControl('', [Validators.required, Validators.minLength(8), this.validatePasswordStrength]);
  confirmPassword = new FormControl('', Validators.required);

  constructor(
    private authService: AuthService,
    // private router: Router,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private localizationService: LocalizationService,
    public notificationService: NotificationService
  ) {
    // The link in the email will include three url parameters: email, token, locale.
    // Get them here.  If we don't get them all then re-route to login.
    this.route.queryParams.subscribe(params => {
      this.token = decodeURIComponent(params.token);
      this.email = decodeURIComponent(params.email);
      this.locale = decodeURIComponent(params.locale);

      if (this.token === 'undefined' || this.email === 'undefined' || this.locale === 'undefined') {
        this.router.navigate(['/login']);
      } else {
        if (this.locale !== 'undefined') {
          localizationService.setLocale(this.locale);
        }
      }

      // Build the form.
      this.form = this.formBuilder.group({
        password: this.password,
        confirmPassword: this.confirmPassword
      });
    });
  }

  ngOnInit() {
    this.passwordSubscription = this.form.get('password').valueChanges.subscribe(val => {
      this.revalidateConfirm();
    });
  }

  ngOnDestroy() {
    if (this.passwordSubscription) {
      this.passwordSubscription.unsubscribe();
    }
  }
  // Listening for Password change to revalidate the confirmPassword
  onChanges(): void {}

  revalidate(name: 'password' | 'confirmPassword') {
    const password: AbstractControl = this.form.controls[name];
    if (!password.invalid && password.touched) {
      password.updateValueAndValidity();
    }
  }
  revalidateConfirm() {
    this.revalidate('confirmPassword');
  }

  validatePasswordStrength(c: FormControl) {
    // const PASSWORD_EXP = RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&]).{8,}');

    const isLower = /[a-z]/.test(c.value);
    const isUpper = /[A-Z]/.test(c.value);
    const isNumber = /[0-9]/.test(c.value);
    const isSpecial = /[^a-zA-Z0-9]/.test(c.value);
    const is8 = c.value.length >= 8;

    if (isLower && isUpper && isNumber && isSpecial && is8) return null;
    else
      return {
        validatePasswordStrength: {
          valid: false
        }
      };
  }

  submit() {
    // Relay the new password to the server.
    if (this.form.valid) {
      this.authService.userResetPassword(this.email, this.token, this.form.value.password).subscribe(
        res => {
          if (res) {
            this.notificationService.successSnackBar(this.localizationService.instant('PersonalInfo.PasswordUpdate'));
            this.router.navigate(['/login']);
          }
        },
        err => {
          this.notificationService.showErrors(err);
        }
      );
    }
  }
}
