import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { environment } from '../environments/environment';
import { LocalizationService } from '@services/localization.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import localEn from '@angular/common/locales/en-CA';
import localFr from '@angular/common/locales/fr-CA';
import { registerLocaleData } from '@angular/common';
import { DateAdapter } from '@angular/material';
import { CheckForUpdateService } from '@services/check-for-update.service';
import { Subscription } from 'rxjs';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_CONSENT_COOKIE_NAME, COOKIE_CONSENT_LANGUAGE_RESOURCE_KEY } from './cookie-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  env = environment;

  private _subs: Subscription[] = [];
  private set addSub(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    translate: TranslateService,
    private localization: LocalizationService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private adapter: DateAdapter<any>,
    private translateService: TranslateService,
    private checkForUpdateService: CheckForUpdateService, //   Doesn't need to be called, its constructor will start it up.
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService
  ) {
    // Cookie   Consent
    this.initCookieConsentLocalization();
    this.initCookieConsentEvents();

    translate.addLangs(['en-CA', 'fr-CA']);
    translate.setDefaultLang('fr-CA');
    localization.setDefaultLocale('fr-CA');
    //  use locale from storage if available

    const storedLocale = localStorage.getItem('locale');
    localStorage.setItem('update-available', 'false');
    if (storedLocale) {
      localization.setLocale(storedLocale);
    } else {
      localization.setLocale('fr-CA');
    }
  }

  ngOnInit() {
    this.localization.initLangChange();
    this.translateService.onLangChange.subscribe(event => {
      this.adapter.setLocale(event.lang);
      this.initCookieConsentLocalization();
    });

    if (!this.authService.loggedIn()) {
      this.authService.logout().subscribe(_ => {
        if (this.route && this.route.routeConfig && this.route.routeConfig.path !== '/reset-password') {
          this.router.navigate(['/login']);
        }
      });
    }
    registerLocaleData(localEn, 'en-CA');
    registerLocaleData(localFr, 'fr-CA');
  }

  initCookieConsentEvents() {
    // subscribe to cookieConsent observables to react to main events
    this.addSub = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log('Cookie Consent Popup Open   ');
    });

    this.addSub = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log('Cookie Consent Closed   ');
      // this.ccService.destroy()
    });

    this.addSub = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
      // the cookieConsent has been successfully initialized.
      // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
      console.log(`Cookie Consent initialized: ${JSON.stringify(event)}`);
    });

    this.addSub = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log('Cookie Consent Status Changed   ', event, this.cookieService.getAll());
      if (event.status === 'deny') {
        // Delete all cookies except the cookie consent one.
        const all = this.cookieService.getAll();
        for (const cookieKey of Object.keys(all)) {
          if (cookieKey !== COOKIE_CONSENT_COOKIE_NAME) this.cookieService.delete(cookieKey);
        }
      }
    });

    this.addSub = this.ccService.revokeChoice$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log('Cookie Consent Revoke Choice   ');
      // this.cookieService.deleteAll();
    });

    this.addSub = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log('Cookie Consent No Cookie Law   ');
    });
  }

  initCookieConsentLocalization() {
    this.translateService.get(COOKIE_CONSENT_LANGUAGE_RESOURCE_KEY).subscribe(localizedText => {
      const config = this.ccService.getConfig();
      config.content = config.content || {};

      // Override default messages with the translated ones
      config.content.allow = localizedText['Allow'];
      config.content.deny = localizedText['Deny'];
      config.content.dismiss = localizedText['Dismiss'];
      config.content.header = localizedText['Header'];
      config.content.href = localizedText['Href'];
      config.content.link = localizedText['Link'];
      config.content.message = localizedText['Message'];
      config.content.policy = localizedText['Policy'];
      config.content.target = localizedText['Target'];
      config.content.close = localizedText['Close'];

      console.log('isDevMode', isDevMode());
      // if (!isDevMode())
      config.enabled = true;

      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(config); //   update config with translated messages
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach(s => s.unsubscribe());
  }
}
