import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { getTimezoneAbbreviation } from '@services/date.service';

@Pipe({
  name: 'kortoTime'
})
export class KortoTimePipe extends DatePipe implements PipeTransform {
  transform(dateValue: any, args?: any): any {
    if (dateValue === undefined) return '';
    const zoneAbbr =
      args === 'noTz' ? ' ' : getTimezoneAbbreviation();
    return super.transform(dateValue, `h:mm a '${zoneAbbr}'`);
  }
}
