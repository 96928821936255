import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { SkillAreaQuestionModel } from '@models/skill-area-question-model';
import { SkillAreaService } from '@services/skill-area.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-skill-area-resume',
  templateUrl: './skill-area-resume.component.html',
  styleUrls: ['./skill-area-resume.component.scss']
})
export class SkillAreaResumeComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject<boolean>();
  skillAreaQuestions: SkillAreaQuestionModel[];
  @Input() userCertificationId: string;

  constructor(private skillAreaService: SkillAreaService, private notificationService: NotificationService) {}
  ngOnInit() {
    this.updateUserSkillArea();
  }

  updateUserSkillArea() {
    this.skillAreaService
      .getUserSkillsArea(this.userCertificationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        results => {
          this.skillAreaQuestions = results;
        },
        error => this.notificationService.showErrors(error)
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('userCertificationId' in changes) {
      // When the selected user certification changes, update the questionnaire's answers.
      this.updateUserSkillArea();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
