import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: '[kj-error]',
  templateUrl: './kj-error.component.html',
  styleUrls: ['./kj-error.component.scss']
})
export class KjErrorComponent {
  @Input() control: FormControl;
}
