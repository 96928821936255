import { Component, OnInit } from '@angular/core';
import { SiteConfigModel } from '@models/site-config-model';
import { SiteConfigService } from '@services/siteConfig.service';

@Component({
  selector: 'app-popup-support',
  templateUrl: './popup-support.component.html',
  styleUrls: ['./popup-support.component.scss']
})
export class PopupSupportComponent implements OnInit {
  siteConfig: SiteConfigModel;
  constructor(public siteConfigService: SiteConfigService) {}

  ngOnInit() {
    this.siteConfigService.getSiteConfig().subscribe(siteConfig => {
      this.siteConfig = siteConfig;
    });
  }
}
