import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LocalizationService } from '@services/localization.service';

@Component({
  selector: 'app-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.css']
})
export class OkDialogComponent implements OnInit {
  defaults: any;

  constructor(
    public dialogRef: MatDialogRef<OkDialogComponent>,
    private localizationService: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.localizationService.translate('Global').subscribe(result => {
      this.defaults = {
        titleText: result.Confirm,
        okText: result.Confirm
      };
      this.data = Object.assign(this.defaults, this.data);
    });
  }
}
