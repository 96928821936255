import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getTranslation',
  pure: false
})
export class GetTranslationPipe implements PipeTransform {
  translation: string = '';
  constructor(private translateService: TranslateService) {}

  transform(value: any, languageCode: string = 'en-CA'): any {
    if (this.translation === '') {
      this.translateService
        .getTranslation(languageCode)
        .pipe(
          map(translations => {
            return this.translateService.getParsedResult(translations, value);
          })
        )
        .subscribe(res => {
          this.translation = res;
        });
    }

    return this.translation;
  }
}
