import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentService, NgcCookieConsentStatus } from 'ngx-cookieconsent';
import { Subject, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

    private _subscribers = new Array<any>();
  private _langSubject = new Subject<string>();

  private readonly CULTURE_COOKIE_KEY = '.AspNetCore.Culture'

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private cookieConsentService: NgcCookieConsentService
  ) {}

  private hasAnsweredAndConsented() {
    const config = this.cookieConsentService.getConfig();
    if (!config.enabled) return true;
    const hasAnswered = this.cookieConsentService.hasAnswered();
    const hasConsented = this.cookieConsentService.hasConsented();
    return hasAnswered && hasConsented;
  }

  setDefaultLocale(locale: string) {
    if (this.hasAnsweredAndConsented()) this.cookieService.set(this.CULTURE_COOKIE_KEY, `c=${locale}|uic=${locale}`);

    localStorage.setItem('default-locale', locale);
  }

  setLocale(locale: string) {
    if (this.hasAnsweredAndConsented())
      this.cookieService.set(this.CULTURE_COOKIE_KEY, `c=${locale}|uic=${locale}`, null, '/');

    localStorage.setItem('locale', locale);
    this.translateService.use(locale);
  }

  /**
   * Get the locale code.  Example: fr-CA
   */
  getLocale() {
    return localStorage.getItem('locale') || localStorage.getItem('default-locale') || 'fr-CA';
  }

  /**
   * Gets the text for the currently selected locale.  Returns an Observable.
   * @param key translation key
   */
  translate(key: string | string[], interpolateParams?: Object) {
    return this.translateService.get(key, interpolateParams);
  }

  /**
   * Instantly gets the text for the currently selected locale.
   * see: https://github.com/ngx-translate/core
   * @param key translation key
   */
  instant(key: string | string[], interpolateParams?: Object) {
    return this.translateService.instant(key, interpolateParams);
  }

  addLangSubscriber(subscriber: Subscriber<any>, action: CallableFunction) {
    this._subscribers.push({
      subscriber: subscriber,
      action: action
    });
  }

  initLangChange() {
    this.translateService.onLangChange.subscribe(event => {
      if (this._subscribers.length) {
        // Filter out any subs that have been unsubscribed.
        this._subscribers = this._subscribers.filter(sub => !(sub.subscriber as Subscriber<any>).closed);
        console.log('Lang Change Subs: ', this._subscribers);
        for (const sub of this._subscribers) {
          sub.action(sub.subscriber);
        }
      }
    });
  }
}
