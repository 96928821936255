import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '@services/localization.service';

@Pipe({
  name: 'kortoDateLong',
  pure: false // Set pure to false so that month/day names will update when the language is changed.
})
export class KortoDateLongPipe extends DatePipe implements PipeTransform {
  constructor(private localService: LocalizationService) {
    super(localService.getLocale());
  }
  transform(value: any, args?: any): any {
    return super.transform(value, 'EEEE MMMM dd, yyyy', null, this.localService.getLocale());
  }
}
