import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TestScheduleEvaluatorModel } from '@models/test-schedule-evaluator-model';
import { EvaluatorService } from '@services/evaluator.service';
import { NotificationService } from '@services/notification.service';
import { addYears } from 'date-fns';
import { Moment } from 'moment';

@Component({
  selector: 'app-test-date-time-selector',
  templateUrl: './test-date-time-selector.component.html',
  styleUrls: ['./test-date-time-selector.component.scss']
})
export class TestDateTimeSelectorComponent implements OnInit {
  @Input() userTestId: string;
  @Input() startDate: Date;
  @Input() evaluatorsRequired: number;
  @Input() durationMinutes: number;
  @Output() initialSelection: EventEmitter<Date> = new EventEmitter();
  @Output() selectionChanged: EventEmitter<TestScheduleEvaluatorModel> = new EventEmitter();

  minDate = new Date();
  maxDate = addYears(new Date(), 1);
  availableDates: Date[] = [];

  availableStartTimes: TestScheduleEvaluatorModel[] = [];
  selectedDateTime: TestScheduleEvaluatorModel = null;
  testDurationMs: number = 0;
  isLoading = true;
  lastSelectedDate: Date;

  constructor(private evaluatorService: EvaluatorService, private notificationService: NotificationService) {
    this.dateFilter = this.dateFilter.bind(this);
  }

  ngOnInit() {
    this.getAllAvailableDays(this.userTestId);
  }

  getAllAvailableDays(userTestId: string) {
    this.isLoading = true;
    this.evaluatorService
      .getAvailableDays(userTestId, this.evaluatorsRequired, this.durationMinutes)
      .subscribe(dates => {
        // dates is an array of available dates, but the first item will be the currently selected date or null if there is none.
        const currentlySelectedDate = dates[0];
        this.availableDates = dates.slice(1);
        this.isLoading = false;

        // Constrain the date picker to what's available.
        this.availableDates.sort((a, b) => (a.getTime() < b.getTime() ? -1 : 1));
        this.minDate = this.availableDates[0];
        this.maxDate = this.availableDates[this.availableDates.length - 1];

        if (!this.startDate) this.startDate = currentlySelectedDate || this.minDate;
        if (this.startDate) this.showStartTimes(this.userTestId, new Date(this.startDate.setHours(0, 0, 0, 0)));

        // If there is a selected date let the parent component know about it.
        if (currentlySelectedDate) this.initialSelection.emit(currentlySelectedDate);
      });
  }

  dateFilter(d: Moment | null): boolean {
    if (!d) return true;
    const date = d.local(true).toDate();
    return this.availableDates.map(x => x.getTime()).includes(date.getTime());
  }

  dateSelectionChanged(testId: string, date: Moment) {
    this.showStartTimes(testId, date.utc().toDate());
  }

  showStartTimes(testId: string, date: Date = this.lastSelectedDate) {
    if (!date) return;
    this.lastSelectedDate = date;
    // Get list of possible start times
    this.isLoading = true;
    this.evaluatorService
      .getTestScheduleOptionsForDay(testId, date, this.evaluatorsRequired, this.durationMinutes)
      .subscribe(results => {
        this.isLoading = false;
        this.availableStartTimes = results.startTimesWithEvaluators
          .filter(x => x.startTime.getTime() > Date.now())
          .sort((a, b) => (a.startTime.getTime() < b.startTime.getTime() ? -1 : 1));

        // Is there a time selected already?
        if (results.scheduledStartTime)
          this.selectedDateTime = results.startTimesWithEvaluators.find(
            x => x.startTime.getTime() === results.scheduledStartTime.getTime()
          );
        this.testDurationMs = results.testDurationMinutes * 60 * 1000;
      });
  }

  /**
   * A time was selected. Toggle the selection and emit it.
   * @param testId The id of the user test
   * @param model the time that was selected.
   */
  selectDateTime(model: TestScheduleEvaluatorModel) {
    if (this.selectedDateTime && this.selectedDateTime.startTime.getTime() === model.startTime.getTime())
      this.selectedDateTime = null;
    else this.selectedDateTime = model;

    // Return the value to the parent component.
    this.selectionChanged.emit(this.selectedDateTime);
  }
}
