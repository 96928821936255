import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from '@services/localization.service';

@Component({
  selector: 'app-language-toggle',
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.scss']
})
export class LanguageToggleComponent implements OnInit {
  @Input() colorName: string;
  @Input() className: string;
  @Output() change: EventEmitter<string> = new EventEmitter();

  public localeText: string;
  public locale: string;

  constructor(
    public localizationService: LocalizationService,
    private translateService: TranslateService
  ) {
    this.colorName = '';
    this.className = '';
  }

  ngOnInit() {
    this.locale = this.localizationService.getLocale();
    this.translateService
      .get(`Locale.${this.locale}`)
      .subscribe((res: string) => {
        this.localeText = res;
      });
  }

  setLocale(locale: string) {
    this.locale = locale;
    this.localizationService.setLocale(locale);
    this.translateService.get(`Locale.${locale}`).subscribe((res: string) => {
      this.localeText = res;
    });
    this.change.emit(locale);
  }
}
