import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'kj-time-selector',
  templateUrl: './kj-time-selector.component.html',
  styleUrls: ['./kj-time-selector.component.scss']
})
export class KjTimeSelectorComponent implements OnInit, OnChanges {
  // @Input() form: FormGroup;
  // @Input() controlName: string;
  @Input() control: FormControl;
  @Input() label: string;

  // Start and end time are in minutes.
  @Input() startTime = 0;
  @Input() endTime = 23 * 60 + 59;
  @Input() timeInterval = 15;

  public times: number[] = [];
  public firstSelected;

  constructor(private formBuilder: FormBuilder) {
    this.updateTimeOptions()

    // It'd be nice if the select box was scrolled down to noon when it opened, so users would not have to scroll as far.
    // Not sure how to do that.
    // this.firstSelected = this.times[Math.round(this.times.length / 2)]
    // console.warn(this.firstSelected)
  }

  /**
   * Make list of times (in minutes) for time select box
   */
  private updateTimeOptions() {
    this.times = [];
    for (let minute = this.startTime; minute < this.endTime; minute += this.timeInterval) {
      this.times.push(minute);
    }
  }

  ngOnInit() {
    this.control.setValue(this.roundToInterval(this.control.value));
  }

  ngOnChanges() {
    this.updateTimeOptions();
  }

  public get timeInMinutes(): number {
    return this.control.value;
  }

  /**
   * Format the time in minutes to hour:minute am or pm.
   * @param inputMinutes Minutes since midnight.
   * @returns Formatted time for display in UI.
   */
  formatTime(inputMinutes: number) {
    let hour = Math.floor(inputMinutes / 60);
    let minute = (inputMinutes - hour * 60) % 60;
    let ampm = hour < 12 ? 'am' : 'pm';
    hour = hour > 12 ? hour - 12 : hour < 1 ? 12 : hour;
    return `${hour}:${minute < 10 ? '0' : ''}${minute} ${ampm}`;
  }

  /**
   * Round the time (in minutes) to the nearest interval.
   * @param minutes The value (in minutes) that needs adjusting.
   * @returns
   */
  roundToInterval(minutes: number) {
    // Get the minutes since last nearest interval
    const diff = minutes % this.timeInterval;
    // Move value to the nearest
    minutes = diff < this.timeInterval / 2 ? minutes - diff : minutes + (this.timeInterval - diff);
    // Make sure we're still in the range
    minutes = minutes < this.startTime ? this.startTime : minutes;
    minutes = minutes > this.endTime ? this.endTime : minutes;
    return minutes;
  }
}
