import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

/** All of the cookie consent text can be found under this key in the localization resource files. */
export const COOKIE_CONSENT_LANGUAGE_RESOURCE_KEY = 'CookieConsent';

/** The name of the cookie that will store the user's choice on cookie consent. */
export const COOKIE_CONSENT_COOKIE_NAME = 'cookie-consent-status';

export const cookieConfig: NgcCookieConsentConfig = {
  /**
   * If false, this prevents the popup from showing (useful for giving to control to another piece of code)
   */
  enabled: false,

  /**
   * Defaults cookie options - it is RECOMMENDED to set these values to correspond with your server
   */
  cookie: {
    // domain:  'localhost', // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    domain: document.location.hostname,
    name: COOKIE_CONSENT_COOKIE_NAME
  },

  /**
   * If you want custom colours, pass them in here. this object should look like this.
   * ideally, any custom colours/themes should be created in a separate style sheet, as this is more efficient.
   *
   * {
   *     popup: {background: '#000000', text: '#fff', link: '#fff'},//
   *     button: {background: 'transparent', border: '#f8e71c', text: '#f8e71c'},//
   *     highlight: {background: '#f8e71c', border: '#f8e71c', text: '#000000'},//
   * }
   *
   * `highlight` is optional and extends `button`. if it exists, it will apply to the first button
   * only background needs to be defined for every element. if not set, other colors can be calculated from it
   */
  // palette?: NgcPaletteOptions;
  palette: {
    popup: {
      background: '#9B3328 ',
      link: '#fff',
      text: '#fff'
    },
    button: {
      background: '#fff',
      link: '#fff',
      text: '#9B3328'
    },
    highlight: {
      background: '#fff1',
      text: '#fff'
    }
  },
  theme: 'block',
  type: 'opt-out',
  position: 'bottom-left',

  /**
   * Optional (expecting a HTML element) if passed, the popup is appended to this element. default is `document.body`
   */
  // container?: HTMLElement;
  // This doesn't appear to have any affect
  // container: document.querySelector('app-root > body')

  /**
   * Each item defines the inner text for the element that it references
   */

  // content is set in `AppComponent`.
  // content?: NgcContentOptions;

  /**
   * This is the HTML for the elements above. The string {{header}} will be replaced with the equivalent text below.
   * You can remove '{{header}}' and write the content directly inside the HTML if you want.
   * - ARIA rules suggest to ensure controls are tabbable (so the browser can find the first control),
   *    and to set the focus to the first interactive control (http://w3c.github.io/aria-in-html/)
   */
  // elements?: NgcHTMLElements;

  /**
   * The placeholders {{classes}} and {{children}} both get replaced during initialisation:
   * - {{classes}} is where additional classes get added
   * - {{children}} is where the HTML children are placed
   */
  // window?: string;

  /**
   * This is the html for the revoke button. This only shows up after the user has selected their level of consent
   * It can be enabled of disabled using the `revokable` option
   */
  // revokeBtn?: string;
  // revokeBtn: 'This is the revoke button',

  /**
   * Define types of 'compliance' here. '{{value}}' strings in here are linked to `elements`
   */
  // compliance?: NgcCompliance;

  /**
   * define layout layouts here
   */
  // layouts?: NgcLayouts;

  /**
   * Default layout (see above)
   */
  // layout?: NgcCookieLayout;

  /**
   * Available styles
   * - block (default, no extra classes)
   * - edgeless
   * - classic
   * Use your own style name and use `.cc-theme-STYLENAME` class in CSS to edit.
   * Note: style 'wire' is used for the configurator, but has no CSS styles of its own, only palette is used.
   */
  // theme?: NgcCookieTheme;

  /**
   * The popup is `fixed` by default, but if you want it to be static (inline with the page content), set this to false
   * Note: by default, we animate the height of the popup from 0 to full size
   */
  // static?: boolean;

  /**
   * Some countries REQUIRE that a user can change their mind. You can configure this yourself.
   * Most of the time this should be false, but the `cookieconsent.law` can change this to `true` if it detects that it should
   */
  // revokable?: boolean;
  // This doesn't appear to have any affect.
  // revokable: true,
  // revokable: false,

  /**
   * If true, the revokable button will tranlate in and out
   */
  // animateRevokable?:    boolean;

  // The Revokable button is a little tab that sits at the bottom of the screen once the user has chosen "deny" or "Accept".  Clicking it will
  // revoke the choice, causing the consent box to show again.  If `true` then the tab will hide until mouseover.  If `false` then it will always show.
  animateRevokable: true,

  /**
   * Used to disable link on existing layouts
   * replaces element messagelink with message and removes content of link
   */
  // showLink?: boolean;

  // Whether to show a link after the message.  The URL for the link will be taken from the locale resource file: CookieConsent.Href and text will come from: CookieConsent.Link
  showLink: false,

  /**
   * Set value as scroll range to enable
   */
  // dismissOnScroll?: boolean | number;

  /**
   * Set value as time in milliseconds to autodismiss after set time
   */
  // dismissOnTimeout?: boolean | number;

  /**
   * The application automatically decide whether the popup should open.
   * Set this to false to prevent this from happening and to allow you to control the behaviour yourself
   */
  // autoOpen?: boolean;

  /**
   * By default the created HTML is automatically appended to the container (which defaults to <body>). You can prevent this behaviour
   * by setting this to false, but if you do, you must attach the `element` yourself, which is a public property of the popup instance:
   *
   * var instance = cookieconsent.factory(options);
   * document.body.appendChild(instance.element);
   *
   */
  // autoAttach?: boolean;

  /**
   * Simple whitelist for pages. specify page by:
   * - using a string : '/index.html'           (matches '/index.html' exactly) OR
   * - using RegExp   : /\/page_[\d]+\.html/    (matched '/page_1.html' and '/page_2.html' etc)
   *
   */
  // whitelistPage?: string[];

  /**
   * Simple blacklist for pages. specify page by:
   * - using a string : '/index.html'           (matches '/index.html' exactly) OR
   * - using RegExp   : /\/page_[\d]+\.html/    (matched '/page_1.html' and '/page_2.html' etc)
   *
   */
  // blacklistPage?: string[];

  /**
   * If this is defined, then it is used as the inner html instead of layout. This allows for ultimate customisation.
   * Be sure to use the classes `cc-btn` and `cc-allow`, `cc-deny` or `cc-dismiss`. They enable the app to register click
   * handlers. You can use other pre-existing classes too. See `src/styles` folder.
   *
   */
  // overrideHTML?: string;

  /**
   * Law Options
   */
  // law?: NgcLawOptions;
  // law: { countryCode: 'CA' }

  /**
   * Location Options
   */
  // location?: boolean | NgcLocationOptions;

  /**
   * Set value as click anything on the page, excluding the `ignoreClicksFrom` below (if we click on the revoke button etc)
   */
  // dismissOnWindowClick?: boolean;

  /**
   * If `dismissOnWindowClick` is true, we can click on 'revoke' and we'll still dismiss the banner, so we need exceptions.
   * Should be an array of class names (not CSS selectors).
   */
  // ignoreClicksFrom?: string[];
};
