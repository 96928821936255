import { RouterModule, Routes } from '@angular/router';

// Login
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LoginGuard } from './login.guard';
import { DetailsComponent } from './register/details/details.component';
import { PreventLeavingConfirm } from '@shared/guards/prevent-leaving.guard';
import { CreateNewPasswordComponent } from './create-new-password/create-new-password.component';

const authRoutes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'reset-password',
        component: CreateNewPasswordComponent
        // canActivate: [LoginGuard]
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'register/details',
        component: DetailsComponent,
        canDeactivate: [PreventLeavingConfirm],
        data: {
          wideCard: true
        }
      },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
    ]
  }
];

export const AuthRoutes = RouterModule.forChild(authRoutes);
