import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, catchError } from 'rxjs/operators';
import { UserRegisterModel } from '@models/user-register-model';
import { UserSessionModel } from '@models/user-session-model';
import { UserLoginModel } from '@models/user-login-model';
import { Router } from '@angular/router';
import { TokenModel } from '@models/token-model';
import { throwError } from 'rxjs';
import { ChangePasswordModel } from '@models/change-password-model';
import { ChangePasswordResponseModel } from '@models/change-password-response-model';
import { Roles } from '@models/roles';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwtHelperService = new JwtHelperService();
  apiUrl = '';
  private _examInProgress = false;
  private idleTimer: NodeJS.Timer;

  public get examInProgress(): boolean {
    return this._examInProgress;
  }

  public set examInProgress(value: boolean) {
    console.log('%cExam in progress: ', 'color: #fa0', value);
    this._examInProgress = value;
  }

  public get activity(): number {
    const act = Number(localStorage.getItem('activity'));
    if (act === 0) {
      return Date.now();
    }
    return act;
  }
  public set activity(value: number) {
    localStorage.setItem('activity', value.toString());
  }

  private get accessToken() {
    return localStorage.getItem('token');
  }
  private set accessToken(value: string) {
    if (value === null) {
      localStorage.removeItem('token');
    } else {
      localStorage.setItem('token', value);
    }
  }

  private get refreshToken() {
    return localStorage.getItem('refresh');
  }
  private set refreshToken(value: string) {
    if (value === null) {
      localStorage.removeItem('refresh');
    } else {
      localStorage.setItem('refresh', value);
    }
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
    private matDialog: MatDialog
  ) {
    this.apiUrl = `${this.baseUrl}api/authentication`;
  }

  register(userRegisterModel: UserRegisterModel) {
    return this.http.post<UserRegisterModel>(`${this.apiUrl}/register`, userRegisterModel);
  }

  login(userLoginModel: UserLoginModel) {
    return this.http.post<TokenModel>(`${this.apiUrl}/login`, userLoginModel).pipe(
      map(response => {
        // login successful if there's a jwt token in the response
        if (response && response.accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.accessToken = response.accessToken;
          this.setUser(response.user);
          // the jwt refresh token will wait until the access token is about to expire
          this.refreshToken = response.refreshToken;
          this.setIdleTimeout(this.accessToken, Date.now());
        }
        return response.user;
      })
    );
  }

  private setIdleTimeout(token: string, activity: number) {
    // if there is an exam in progress, that is considered activity
    if (this.examInProgress) {
      activity = Date.now();
    }
    this.activity = activity;
    // how long ago was the last time a message was sent to the server?
    // we want to subtract that from the timeout.
    const last = Date.now() - activity;
    const next = this.jwtHelperService.getTokenExpirationDate(token).getTime() - Date.now();
    // and only set the timer if it's in the positives
    if (next > last + 1_000) {
      this.idleTimer = setTimeout(this.scopeCall, next - last, this.refreshJwsToken, this, activity);
      console.log(new Date(Date.now() + next - last));
      return true;
    } else {
      console.log('Session expired');
      this.logoutNow();
      return false;
    }
  }

  private scopeCall(callback, $this: object, ...$params: any[]) {
    callback.call($this, ...$params);
  }

  private refreshJwsToken(previous: number) {
    if (!this.accessToken) return;
    const activity = this.activity;
    const token = this.accessToken;
    const next = this.jwtHelperService.getTokenExpirationDate(token).getTime() - Date.now();
    if (activity > previous && next > 1000) {
      console.log('Waiting a little longer');
      this.idleTimer = setTimeout(this.scopeCall, next, this.refreshJwsToken, this, activity);
      return;
    }

    this.http
      .post<TokenModel>(`${this.apiUrl}/login/refresh`, {
        accessToken: token,
        refreshToken: this.refreshToken
      } as TokenModel)
      .pipe(
        catchError(error => {
          console.log('Failed to refresh token(2)');
          this.logoutNow();
          return throwError(error);
        })
      )
      .subscribe(response => {
        if (response.accessToken) {
          console.log('Refreshing token');
          this.accessToken = response.accessToken;
          this.refreshToken = response.refreshToken;
          this.setIdleTimeout(response.accessToken, activity);
        } else {
          console.log('Failed to refresh token(1)');
          this.logoutNow();
        }
      });
  }

  logout() {
    // Don't leave dialogs open.
    this.matDialog.closeAll();

    // remove user from local storage to log user out
    this.setUser(null);
    this.accessToken = null;

    // cancel the idle timer
    clearTimeout(this.idleTimer);
    this.idleTimer = null;
    this.refreshToken = null;
    return this.http.post<any>(`${this.apiUrl}/logout`, {}, {});
  }

  logoutNow() {
    this.logout().subscribe(_ => this.router.navigate(['/login']));
  }

  loggedIn() {
    if (this.getUser() === null) {
      return false;
    } else if (this.jwtHelperService.isTokenExpired(this.accessToken)) {
      return false;
    } else if (!this.idleTimer) {
      return this.setIdleTimeout(this.accessToken, Date.now());
    }
    return true;
  }

  roleMatch(allowedRoles): boolean {
    let isMatch = false;

    const userRoles = this.getUserRoles();

    allowedRoles.forEach(element => {
      if (userRoles.includes(element)) {
        isMatch = true;
        return;
      }
    });

    return isMatch;
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user')) as UserSessionModel;
  }
  private setUser(value: UserSessionModel) {
    if (value === null) {
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('user', JSON.stringify(value));
    }
  }

  getUserId(): string {
    const user = this.getUser();
    if (user) {
      return user.id;
    }
    return null;
  }

  /**
   * For use by administrators to reset users' passwords for them
   * Resets a user's password to a randomly created password.  The
   * admin will need to forward the new password to the user.
   * @param userId The ID of the user whose password will be reset.
   */
  resetPassword(userId: string) {
    return this.http.put<{ password: string }>(
      `${this.apiUrl}/resetPassword`,
      {},
      {
        params: {
          userId: userId
        }
      }
    );
  }

  /**
   * For use by users.
   * Request that an email be sent to this email address if the address is valid.
   * A code will be sent in the email that can be used to create a new password.
   * @param email The user's email address.
   */
  forgotPassword(email: string, locale: string) {
    return this.http.put<any>(
      `${this.apiUrl}/forgotPassword`,
      {},
      {
        params: {
          email,
          locale
        }
      }
    );
  }

  /**
   * Enables users to change they're own password if they forgot it.
   * @param email Users email address
   * @param token The code the user received in their email
   * @param newPassword The new password the user just created.
   */
  userResetPassword(email: string, token: string, newPassword: string) {
    return this.http.put<any>(`${this.apiUrl}/userResetPassword`, {
      email,
      token,
      newPassword
    });
  }

  changePassword(passwords: ChangePasswordModel) {
    return this.http.put<ChangePasswordResponseModel>(`${this.apiUrl}/changePassword`, passwords);
  }

  getToken(): string {
    return this.accessToken;
  }

  isCurrentUserCandidate(): boolean {
    return this.userHasRole(Roles.candidate);
  }

  isCurrentUserAdmin(): boolean {
    return this.userHasRole(Roles.admin);
  }

  isCurrentUserEvaluator(): boolean {
    return this.userHasRole(Roles.evaluator);
  }

  isCurrentUserThirdParty(): boolean {
    return this.userHasRole(Roles.thirdParty);
  }

  setUserHasRegisteredForACertification() {
    const user = this.getUser();
    user.hasRegisteredForACertification = true;
    this.setUser(user);
  }

  setUserHasCompletedDetails() {
    const user = this.getUser();
    user.hasMissingDetails = false;
    this.setUser(user);
  }

  private getDecodedToken(): any {
    if (this.accessToken === null) return null;
    return this.jwtHelperService.decodeToken(this.accessToken);
  }

  private userHasRole(role: string) {
    return this.getUserRoles().indexOf(role) >= 0;
  }

  private getUserRoles(): Array<string> {
    const token = this.getDecodedToken();
    return (token ? token.role : null) as Array<string>;
  }
}
