import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-card-footer',
  templateUrl: './auth-card-footer.component.html'
})
export class AuthCardFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
