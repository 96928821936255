import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup
} from '@angular/forms';
import { UserEmailRecipientModel } from '@models/user-email-recipient-model';
import { NotificationService } from '@services/notification.service';
import { TitleService } from '@services/title.service';
import { TitleModel } from '@models/title-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-third-party-emails',
  templateUrl: './third-party-emails.component.html',
  styleUrls: ['./third-party-emails.component.scss']
})
export class ThirdPartyEmailsComponent implements OnInit, OnDestroy {
  @Input() emailRecipients: UserEmailRecipientModel[];
  @Input() readonly: boolean = false;
  @Output() change = new EventEmitter();
  @Output() emailAdded = new EventEmitter();
  @Output() emailUpdated = new EventEmitter();
  @Output() emailDeleted = new EventEmitter();
  isInEditMode = false;
  editIndex: number;
  titles: TitleModel[];

  destroy$ = new Subject<boolean>();

  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private titleService: TitleService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.titleService
      .getTitles()
      .pipe(takeUntil(this.destroy$))
      .subscribe((titles: TitleModel[]) => {
        this.titles = titles;
      });
    this.setupForm();
    if (this.emailRecipients.length === 0) {
      this.add();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  add() {
    this.isInEditMode = true;
    this.editIndex = -1;
    this.form.reset();
  }

  submit() {
    if (this.form.valid) {
      if (this.editIndex > -1) {
        const existing = this.emailRecipients[this.editIndex];

        Object.assign(existing, this.form.value);

        this.change.emit({ action: 'Edit', item: existing });
        this.emailUpdated.emit(existing);
      } else {
        const emailRecipientModel = this.form.value as UserEmailRecipientModel;
        this.emailRecipients.push(emailRecipientModel);

        this.change.emit({ action: 'Add', item: emailRecipientModel });
        this.emailAdded.emit(emailRecipientModel);
      }

      this.reset();
    }
  }

  edit(emailRecipientModel: UserEmailRecipientModel, index: number) {
    this.form.setValue(emailRecipientModel);
    this.isInEditMode = true;
    this.editIndex = index;
  }

  delete(emailRecipientModel: UserEmailRecipientModel) {
    this.notificationService
      .confirmDialog({
        data: {
          messageKey: 'Notifications.ConfirmRemoveMessage'
        }
      })
      .afterClosed()
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          const index = this.emailRecipients.indexOf(emailRecipientModel);
          this.emailRecipients.splice(index, 1);

          this.change.emit({ action: 'Delete', item: emailRecipientModel });
          this.emailDeleted.emit(emailRecipientModel);

          if (this.emailRecipients.length === 0) {
            this.add();
          }
        }
      });
  }

  cancel() {
    this.reset();
  }

  setEnabled(enabled: boolean) {
    if (enabled) {
      this.enable();
    } else {
      this.disable();
    }
  }

  private disable() {
    this.form.disable();
  }

  private enable() {
    this.form.enable();
  }

  private reset() {
    this.form.reset();
    this.isInEditMode = false;
    this.editIndex = null;
  }

  private setupForm() {
    this.isInEditMode = false;
    this.form = this.formBuilder.group({
      id: new FormControl(''),
      titleId: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  titleIdtoLabel(id: string) {
    if (this.titles) {
      const title = this.titles.find(item => {
        return item.id === id;
      });

      if (title) {
        return title.label;
      }
    }
  }
}
