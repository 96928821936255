import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommunicationModel } from '@models/communication-model';
import { LocalizationService } from '@services/localization.service';

@Component({
  selector: 'app-communication-popup',
  templateUrl: './communication-popup.component.html',
  styleUrls: ['./communication-popup.component.scss']
})

// NOTE: Communications are called Announcements in the UI.
export class CommunicationPopupComponent implements OnInit {
  communication: CommunicationModel;

  constructor(
    public dialog: MatDialogRef<CommunicationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public popupData: any,
    public localeService: LocalizationService
  ) {
    dialog.disableClose = true;
  }

  ngOnInit() {
    this.communication = this.popupData.communication;
  }
}
