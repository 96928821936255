import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProvinceModel } from '@models/province-model';
import { Observable, Subscriber } from 'rxjs';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {
  apiUrl: string;

  static cache: any = {};

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private localizationService: LocalizationService
  ) {
    this.apiUrl = `${this.baseUrl}api/provinces`;
  }

  getProvinces() {
    return new Observable<ProvinceModel[]>(subscriber => {
      this.getProvincesCall(subscriber);
      this.localizationService.addLangSubscriber(subscriber, obs => this.getProvincesCall(obs));
    });
  }

  private getProvincesCall(subscriber: Subscriber<ProvinceModel[]>) {
    const code = this.localizationService.getLocale();

    if (ProvinceService.cache[code]) {
      subscriber.next(ProvinceService.cache[code]);
      return;
    }

    this.http.get<ProvinceModel[]>(`${this.apiUrl}`).subscribe(
      provinces => {
        ProvinceService.cache[code] = provinces;
        subscriber.next(provinces);
      },
      error => subscriber.error(error)
    );
  }
}
